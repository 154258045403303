import axios from 'axios'

const Base_url =  'https://hridayam.dasoclothings.in/api'; // 'http://91.108.104.122/api';
   console.log(sessionStorage.getItem('authtoken'))
const Api = axios.create({
    baseURL: Base_url,
    headers: {
        'Accept': 'application/json',
        // Authorization: `Bearer ${sessionStorage.getItem('authtoken')}`
    }
});


Api.interceptors.request.use(config => {
    const token = sessionStorage.getItem('authtoken');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
    } else {
        config.headers['Content-Type'] = 'application/json';
    }
    return config;
}, error => {
    return Promise.reject(error);
});
const ApiService = {
    // getSettingData: (key) => Api.get('get-setting-data',{params:{key}}),
        login : (data) => Api.post('login',data),
        getUserprofile : () =>  Api.get('/getUserprofile'),
        userlist : (data) => Api.get('/userlist',{params:data}),
        createCategory : (data) => Api.post('createCategory',data),
        getCategory : () => Api.get('getCategory'),
        deleteCategory : (data) => Api.delete('deleteCategory',{data: data}),
        updateCategory : (data) => Api.put('updateCategory',data),
        createSubCategory : (data) => Api.post('createSubCategory',data),
        getSubCategory : () => Api.get('getSubCategory'),
        getSubCategoryByCategory : (params) => Api.get('/getSubCategoryByCategory',{params}),
        getProductDropdown : () =>Api.get('/getProductfordropdown'),
        createBanner : (data) => Api.post('/createBanner',data),
        getBannerList : () => Api.get('/getBannerList'),
        deleteBanner : (data) => Api.delete('/deleteBanner',{data: data}),
        editBanner : (data) =>   Api.post('/editBanner',data),
        getBottomBanner : () => Api.get('/getBottomBanner'),
        createBottomBanner : (data) => Api.post('/createBottomBanner',data),
        updateBottomBanner : (data) => Api.post('/updateBottomBanner',data),
        deleteBottomBanner : (data) => Api.delete('/deleteBottomBanner',{data:data}),
        getVideoBanner  :  () => Api.get('/getVideoBanner'),
        createVideoBanner : (data) => Api.post('/createVideoBanner',data),
        updateVideoBanner : (data) => Api.post('/editvideoBanner',data),
        deleteVideoBanner : (data) => Api.delete('/deleteVideoBanner',{data: data}),
        createCatelog : (data)  => Api.post('/createCatelog',data),
        getCatelogs : (params) => Api.get('/getCatelogs',{params}),
        deleteCatelog : (data) => Api.delete('/deleteCatelog',{data}),
        editCatalogs : (data) => Api.post('/editCatalogs',data),
        Addproduct : (data) => Api.post('/addproduct',data),
        getProduct : (params) => Api.get('/getProduct',{params}),
        editProduct  : (data) => Api.put('/editProduct',data),
        createCoupon : (data) => Api.post('/createCoupon',data),
        getCoupon : () =>  Api.get('/getCoupon'),
        updateCoupon : (data) => Api.post('/updateCoupon',data),
        deleteCoupon : (data) => Api.delete('/deleteCoupon',{data}),
        manageSettings : (data) => Api.post('/manageSettings',data),
        getAllSettings : (params) => Api.get('/getSettings',{params}),
        getOrders : (params) => Api.get('/getOrdersadmin',{params}),
        addLatestTrend : (data) => Api.post('/addLatestTrend',data),
        getlatestTrend : () =>  Api.get('/getlatestTrend'),
        RemoveLatestTrend : (data) => Api.delete('/RemoveLatestTrend',{data}),
        getOrderdetails  : (params)  => Api.get('/getOrderdetails',{params}),
        ChangeOrderStatus : (data) => Api.post('/ChangeOrderStatus',data),
        CancelorderList  : (params) => Api.get('/CancelorderList',{params}),
        ConfirmedorderList  : (params) => Api.get('/ConfirmedorderList',{params}),
        DeliveredorderList  : (params) => Api.get('/DeliveredorderList',{params}),
        PendingorderList  : (params) => Api.get('/PendingorderList',{params}),

        

};

export default ApiService;
