import React, { useState } from 'react'
import './login.css'
import ApiService from '../../apicontroller/ApiController';
import { useNavigate } from 'react-router-dom';
export default function Login() {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [message, setMessage] = useState();
    const navigate = useNavigate()
    const Login = (e) => {
        e.preventDefault();
        ApiService.login({
            email: email,
            password: password
        }).then((response) => {
            if (response.data.status === true) {
                setMessage(response.data.message)
                const token = response.data.data.token;
                sessionStorage.setItem('authtoken', token);
                navigate('/admin')
            } else {
                setMessage(response.data.message)
            }
        }).catch((error) => console.log(error))
    }
    return (
        <>
            <section className='login_section'>
                <h1>Login</h1>
                <div className='login_cutom_form' onSubmit={Login}>
                    <form>
                        <div className="form-group">

                            <label>
                                Email:
                            </label>
                            <input className='custom_input' type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter Email address' />
                        </div>
                        <div className="form-group">
                        <label>
                            Password: </label>
                            <input className='custom_input' type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Enter password' />
                       
                        </div>
                        <div>
                            <button type="submit" className='button5'>Submit</button>
                        </div>
                    </form>
                    <div className='message'>{message}</div>
                </div>
            </section>
        </>
    )
}
