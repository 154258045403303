import React, { useEffect, useState } from 'react'
import ApiService from '../../apicontroller/ApiController';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';

export default function ListCatalog() {
    const [catalogs, setCatalogs] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const fetchCatalogs = () => {
        setLoading(true);
        ApiService.getCatelogs()
            .then(response => {
                setCatalogs(response.data.data.data);
                setPagination(response.data.data.paginator);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching catalogs:', error);
                setLoading(false);
            });
    };
    useEffect(()=>{
        fetchCatalogs()
    },[]);

    const handlePageChange = (pageNumber) => {
        setLoading(true);
        ApiService.getCatelogs({ 
            page: pageNumber,
         })
            .then(response => {
                setCatalogs(response.data.data.data);
                setPagination(response.data.data.paginator);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching catalogs:', error);
                setLoading(false);
            });
    };

    const deleteCatelog =(catalogId)=>{
        ApiService.deleteCatelog({
          catalog_id:catalogId
        }).then((response)=>{
            if(response.data.status === true){
                toast.success(response.data.message);    
                fetchCatalogs()
            }else{
                console.log(response.data)
                toast.success(response.data.message);
            }
        }).catch((error)=>{
            console.error('Error deleting catalog:', error);
            toast.error(error.response.data.message);
        })
    }

    if (loading) return <p>Loading...</p>;

    const handleEdit =(id)=>{
        navigate(`/admin/catalog/UpdateCatalog/${id}`)
    }
  return (
    <>
        <section>
            <h2>Catalogue List</h2>

<table className='table'>
<thead>
<tr>
    <th>S.No.</th>
    <th>Title</th>
    <th>Content</th>
    <th>Image</th>
    <th>Action</th>
</tr>
</thead>
<tbody>
{catalogs.length > 0 ? (
                            catalogs.map((catalog, index) => (
                                <tr key={catalog._id}>
                                    <td>{index + 1}</td>
                                    <td>{catalog.title}</td>
                                    <td>{catalog.content}</td>
                                    <td>
                                        <img
                                            src={catalog.image}
                                            alt={catalog.title}
                                            style={{ width: '150px' }} // Adjust styling as needed
                                        />
                                    </td>
                                    <td>
                                        <button onClick={()=>handleEdit(catalog._id)}><i class='bx bx-edit-alt'></i></button>
                                        <button onClick={()=>deleteCatelog(catalog._id)}> <i class='bx bx-trash'></i></button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">No catalogs found</td>
                            </tr>
                        )}
</tbody>

</table>
 <div className="pagination">
                    {pagination.totalPages > 1 && (
                        <>
                            {pagination.hasPrevPage && (
                                <button onClick={() => handlePageChange(pagination.page - 1)}>Prev</button>
                            )}
                            <span>Page {pagination.page} of {pagination.totalPages}</span>
                            {pagination.hasNextPage && (
                                <button onClick={() => handlePageChange(pagination.page + 1)}>Next</button>
                            )}
                        </>
                    )}
                </div>
        </section>
    </>
  )
}
