import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ApiService from '../../apicontroller/ApiController';
import { toast } from 'sonner';
import { useParams, useNavigate } from 'react-router-dom';

export default function UpdateCoupon() {
  const [title, setTitle] = useState('');
  const [code, setCode] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [users, setUsers] = useState([]);
  const [discountType, setDiscountType] = useState('amount');
  const [minPurchase, setMinPurchase] = useState('');
  const [discount, setDiscount] = useState('');
  const [startDate, setStartDate] = useState('');
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [options, setOptions] = useState([{ value: [], label: 'All users' }]);
  const [image,setImage] =useState();
  const { couponId } = useParams();
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setBackgroundImage(e.target.files[0]);
  };

  const GetUsers = () => {
    ApiService.userlist()
      .then((response) => {
        if (response.data.status === true) {
          const formattedOptions = [
            { value: [], label: 'All users' },
            ...response.data.data.data.map((user) => ({
              value: user._id,
              label: user.name,
            })),
          ];
          setOptions(formattedOptions);
        } else {
          toast.error('Failed to fetch users');
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error('An error occurred while fetching users');
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Create FormData to send data including image
    const formData = new FormData();
    formData.append('coupon_id',couponId);
    formData.append('title', title);
    formData.append('code', code);
    formData.append('start_date', startDate);
    formData.append('expire_date', expireDate);
    if (users.length === 1 && users[0].length === 0) {
        formData.append('users', []);
      } else {
        users.forEach((id,index)=>{
          formData.append(`users[${index}]`, id);
        })
        
      }
    formData.append('discount_type', discountType);
    formData.append('min_purchase', minPurchase);
    formData.append('discount', discount);
    if (backgroundImage) {
      formData.append('image', backgroundImage);
    }

    ApiService.updateCoupon(formData)
      .then((response) => {
        if (response.data.status === true) {
          toast.success('Coupon updated successfully');
          navigate('/admin/coupon/couponlist'); // Redirect after successful update
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    GetUsers();
    ApiService.getCoupon()
      .then((response) => {
        if (response.data.status === true) {
          toast.success(response.data.message);
          const couponList = response.data.data;
          const couponEdit = couponList.find((coupon) => coupon._id === couponId);
          const formattedStartDate = couponEdit.start_date.split('T')[0];
          const formattedExpireDate = couponEdit.expire_date.split('T')[0];
          setCode(couponEdit.code);
          setDiscount(couponEdit.discount);
          setDiscountType(couponEdit.discount_type);
          setExpireDate(formattedExpireDate);
          setMinPurchase(couponEdit.min_purchase);
          setStartDate(formattedStartDate);
          setUsers(couponEdit.users || []);
          setTitle(couponEdit.title);
          setImage(couponEdit.image)
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  }, [couponId]);

  return (
    <>
      <h2 className="add-coupon-title">Update Coupon</h2>
      <form className="add-coupon-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="code">Code</label>
            <input
              type="text"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="form-control"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="startDate">Start Date</label>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="expireDate">Expire Date</label>
            <input
              type="date"
              id="expireDate"
              value={expireDate}
              onChange={(e) => setExpireDate(e.target.value)}
              className="form-control"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="users">Select Users</label>
            <Select
              options={options}
              placeholder="Select Users"
              isSearchable={true}
              isMulti={true}
              value={options.filter(option => users.includes(option.value))}
              onChange={(selectedOptions) => setUsers(selectedOptions ? selectedOptions.map(option => option.value) : [])}
            />
          </div>
          <div className="form-group">
            <label htmlFor="discountType">Discount Type</label>
            <select
              id="discountType"
              value={discountType}
              onChange={(e) => setDiscountType(e.target.value)}
              className="form-control"
            >
              <option value="amount">Amount</option>
              <option value="percent">Percent</option>
            </select>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="minPurchase">Min Purchase</label>
            <input
              type="number"
              id="minPurchase"
              value={minPurchase}
              onChange={(e) => setMinPurchase(e.target.value)}
              className="form-control custom_number_input"
              min="0"
            />
          </div>
          <div className="form-group">
            <label htmlFor="discount">Discount</label>
            <input
              type="number"
              id="discount"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              className="form-control custom_number_input"
              min="0"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="backgroundImage">Upload Background Image</label>
            <input
              type="file"
              id="backgroundImage"
              onChange={handleFileChange}
              className="form-control"
            />
            {backgroundImage ? <>

            {backgroundImage &&  (
              <div className="image-preview">
                <img src={URL.createObjectURL(backgroundImage)} alt="Images Preview" />
              </div>
            )}
            </> : <>
            {image && (
              <div className="image-preview">
                <img src={image} alt="Images Preview" />
              </div>
            )}
            </>}
          </div>
        </div>
        <button type="submit" className="button">
          Submit
        </button>
      </form>
    </>
  );
}
