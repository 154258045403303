import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ApiService from '../../apicontroller/ApiController';
import { useNavigate, useParams } from 'react-router-dom';
export default function UpdateBanner() {
    const [isProduct, setIsProduct] = useState(true);
    const [product, setProduct] = useState([]);
    const [options, setOptions] = useState([]);
    const [productId, setProductId] = useState([]);
    const [categoryId, setCategoryId] = useState([]);
    const [title, setTitle] = useState();
    const [image, setImage] = useState();
    const [file, setfile] = useState();
    const {bannerId} = useParams();
    const navigate = useNavigate();
    const Getproduct = () => {
        ApiService.getProductDropdown().then((response) => {
            if (response.data.status === true) {
                const formattedOptions = response.data.data.map((category) => {
                    return { value: category._id, label: category.name }
                })
                setProduct(formattedOptions)
            } else {
                console.error(response.data);
            }
        }).catch((error) => console.log(error))
    }
    const getcategory = () => {
        ApiService.getCategory().then((response) => {
            if (response.data.status === true) {
                const formattedOptions = response.data.data.map((category) => {
                    return { value: category._id, label: category.name }
                })
                setOptions(formattedOptions)
            } else {
                console.log(response.data.message)
            }
        }).catch((error) => console.log(error))
    }

    const getBanners = () => {

        ApiService.getBannerList().then((response) => {
            if (response.data.status === true) {
                const BannerList = response.data.data;
                const BannerEdit =  BannerList.find((banner)=> banner._id === bannerId)
                console.log(BannerEdit)
                setTitle(BannerEdit.title);
                setfile(BannerEdit.image);
                if (BannerEdit.product_id) {
                    setIsProduct(true);
                    setProductId(BannerEdit.product_id);
                } else {
                    setIsProduct(false);
                    setCategoryId(BannerEdit.category_id);
                }
            } else {
                console.error(response.data);
            }
        }).catch((error) => console.log(error))
    }
    useEffect(() => {
        Getproduct();
        getcategory();
        getBanners();
    }, []);
    const handleisproduct = () => {
        if (isProduct) {
            setCategoryId([])
        } else {
            setProductId([]);
        }

        setIsProduct(!isProduct)
    }

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };
    const handleProductChange = (selectedOptions) => {
        setProductId(selectedOptions.map((option) => option.value));
    };
    const handleCategoryChange = (selectedOption) => {
        setCategoryId(selectedOption.map((option) => option.value));
    };
    const editBanner =(e)=>{
        e.preventDefault();
        const formData = new FormData();
        if (productId) {
            productId.forEach((id, index) => {
                formData.append(`product_id[${index}]`, id)
            })
        }
        if (categoryId) {
            categoryId.forEach((id, index) => {
                formData.append(`category_id[${index}]`, id)
            })
        }
        formData.append('banner_id', bannerId);
        formData.append('title', title);
        formData.append('image', image);
         ApiService.editBanner(formData).then((response)=>{
         if(response.data.status === true){
            console.log(response.data)
            navigate('/admin/banner')
         }else{
            console.log(response.data)
         }
         }).catch((error)=>console.log(error))
    }
  return (
    <>
         <form encType='multipart/form-data' className="add-banner-form" onSubmit={editBanner}>
                <div className='select_product_category'>
                    <h2>Add New Banner</h2>
                    <div className='select_product_category_checkbox'>
                        {isProduct === true ? <span>Select Product</span> : <span>Select Category</span>}
                        <input type='checkbox' onChange={handleisproduct} />
                    </div>
                </div>
                <div className="form-field">
                    {isProduct === true ?
                        <>

                            <label htmlFor="productName">Select Products</label>
                            <Select
                                options={product}
                                placeholder="Select Products"
                                isSearchable={true}
                                isMulti={true}
                                onChange={handleProductChange}
                                value={product.filter(option => productId.includes(option.value))}
                            />
                        </> :
                        <>
                            <label htmlFor="categoryName">Select Categories</label>
                            <Select
                                options={options}
                                placeholder="Select Categories"
                                isSearchable={true}
                                isMulti={true}
                                onChange={handleCategoryChange}
                                value={options.filter(option => categoryId.includes(option.value))}
                            />
                        </>}
                </div>
                <div className="form-field">
                    <label htmlFor="bannertitle">Title</label>
                    <input type="text" id="bannertitle" placeholder='Enter Title' value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div className="form-field">
                    <label htmlFor="bannerImage">Banner Image * (Ratio 3:1)</label>
                    <input type="file" id="bannerImage" onChange={handleImageChange} />
                    {image ? <>
                        {image && (
                        <div className="logo-preview">
                            <img width={'200px'} src={URL.createObjectURL(image)} alt="Logo Preview" />
                        </div>
                    )}
                    </> : 
                    <>
                    {file && (
                        <div className="logo-preview">
                            <img width={'400px'} src={file} alt="Logo Preview" />
                        </div>
                    )}
                    </>}
                </div>
                <button type='submit' className="submit-button" >Submit</button>
            </form>
    </>
  )
}
