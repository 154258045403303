import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import ApiService from '../../apicontroller/ApiController';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
export default function AddCatalog() {
    const [productOptions, setProductOptions] = useState([]);
    const [productId, setProductId] = useState([]);
    const [image,setImage] = useState(null);
    const [content,setcontent] = useState('');
    const [title,setTitle] = useState('');
    const navigate =  useNavigate();
    const GetProductOptions = () => {
        ApiService.getProductDropdown().then((response) => {
            if (response.data.status === true) {
                const formattedOptions = response.data.data.map((category) => {
                    return { value: category._id, label: category.name };
                });
                setProductOptions(formattedOptions);
            } else {
                console.error(response.data);
            }
        }).catch((error) => console.log(error));
    };
    useEffect(()=>{
        GetProductOptions()
    },[]);
    const handleProductChange = (selectedOptions) => {
        setProductId(selectedOptions.map((option) => option.value));
    };
    const handleImageChange = (e)=>{
        setImage(e.target.files[0]);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('content', content);
        productId.forEach((id, index) => {
            formData.append(`product_ids[${index}]`, id);
        });
        formData.append('image', image);
        ApiService.createCatelog(formData).then((response)=>{
            if(response.data.status === true){
                toast.success('Catalog Created Successfully');
                navigate('/admin/catalog/cataloglist');
                setTitle('');
                setcontent('');
                setProductId([]);
                setImage(null);
            }else{
                toast.error(response.data.message)
                console.error(response.data);
            }
        }).catch((error)=>{
            console.log(error);
            toast.error(error.response.data.message)
        })
    }
  return (
    <>
        <section>
        <form onSubmit={handleSubmit}>

             <div>
             <label htmlFor="productName">Select Products</label>
                            <Select
                                options={productOptions}
                                placeholder="Select Products"
                                isSearchable={true}
                                isMulti={true}
                                onChange={handleProductChange}
                                value={productOptions.filter(option => productId.includes(option.value))}
                            />
             </div>
             <div className='form-group'>
             <label>Title</label>
             <input type='text' className='form-control' id='title' value={title} onChange={(e)=>setTitle(e.target.value)} placeholder='Enter Title' required />
             </div>
             <div className='form-group'>
             <label>Content</label>
                <textarea className='form-control' id='content' value={content} onChange={(e)=>setcontent(e.target.value)} placeholder='Enter Content' required />
             </div>
             <div className='form-group'>
                            <label>Catalog Image</label>
                            <input type='file' onChange={handleImageChange} />
                            {image && (
                                <div>
                                    <img src={URL.createObjectURL(image)} alt='images' width={'250px'} />
                                </div>
                            )}
                        </div>
                        <div>
                            <button type='submit' className='btn btn-primary'>Submit</button>
                        </div>
        </form>
        </section>
    </>
  )
}
