import React,{useEffect, useState} from 'react'
import './Coupon.css'
import ApiService from '../../apicontroller/ApiController';
import { toast } from 'sonner';
import { formatDate } from '../../helpers/FormatDate';
import { useNavigate } from 'react-router-dom';
const CouponList = () => {
    const [coupons, setCoupons] = useState([]);
    const navigate = useNavigate()
    useEffect(()=>{
        ApiService.getCoupon().then((response)=>{
             if(response.data.status === true){
                 toast.success(response.data.message);
                 setCoupons(response.data.data)
             }else{
                toast.error(response.data.message)
             }
        }).catch((error)=>{
          console.log(error);
          toast.error(error.response.data.message)
        })
    },[])
    const handleStatusChange = (id) => {
        setCoupons(
          coupons.map((coupon) =>
            coupon.id === id ? { ...coupon, status: !coupon.status } : coupon
          )
        );
      };

      const handleUpdate = (couponId)=>{
        navigate(`/admin/coupon/updatecoupon/${couponId}`)
      }

      const handleDelete = (couponId)=>{
        ApiService.deleteCoupon({
          coupon_id:couponId
        }).then((response)=>{
             if(response.data.status === true){
                 toast.success(response.data.message);
                 setCoupons(coupons.filter((coupon) => coupon._id!== couponId))
             }else{
                toast.error(response.data.message)
             }
        }).catch((error)=>{
          console.log(error);
          toast.error(error.response.data.message)
        })
      }
  return (
    <>
      <div className="coupon-list">
        <h2>Coupon List</h2>
        <div className="search-bar1">
          <input type="text" placeholder="Search here" />
          <button className="search" type="submit">Search</button>
        </div>
        <table className="table2">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Code</th>
              <th>Min Purchase</th>
              <th>Discount</th>
              <th>Discount Type</th>
              <th>Start Date</th>
              <th>Expire Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {coupons.map((coupon,index) => (
              <tr key={coupon._id}>
                <td>{index + 1}</td>
                <td>{coupon.title}</td>
                <td>{coupon.code}</td>
                <td>{coupon.min_purchase}</td>
                <td>{coupon.discount}</td>
                <td>{coupon.discount_type}</td>
                <td>{formatDate(coupon.start_date)}</td>
                <td>{formatDate(coupon.expire_date)}</td>
                <td>
                  <button className="btn btn-sm btn-primary" onClick={()=>handleUpdate(coupon._id)}><i class='bx bx-edit-alt'></i></button>
                  <button className="btn btn-sm btn-danger" onClick={()=>handleDelete(coupon._id)}><i class='bx bx-trash'></i></button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default CouponList