import React, { useState, useEffect, useRef } from 'react';
import './business.css';
import ApiService from '../../apicontroller/ApiController';
import { toast } from 'sonner';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import Swal from 'sweetalert2';

const Businesspage = () => {
    const [businessName, setBusinessName] = useState('');
    const [razorPayKey, setRazorPayKey] = useState('');
    const [razorPaySecret, setRazorPaySecret] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [BottomBannerTitle, setBottomBannerTitle] = useState('');
    const [logoFile, setLogoFile] = useState(null);
    const [showlogoFile, setshowLogoFile] = useState(null);
    const [faviconFile, setFaviconFile] = useState(null);
    const [showfaviconFile, setshowfaviconFile] = useState(true);
    const privacyPolicyRef = useRef(null);
    const termsConditionsRef = useRef(null);
    const GetSettings = () => {
        ApiService.getAllSettings().then((response) => {
            if (response.data.status === true) {
                setBusinessName(response.data.data.business_name)
                setRazorPayKey(response.data.data.razor_pay_key)
                setRazorPaySecret(response.data.data.razor_pay_secret)
                // setRazorPayChecked(response.data.data.razor_pay_key)
                setPhone(response.data.data.phone_number)
                setEmail(response.data.data.email)
                setBottomBannerTitle(response.data.data.bottom_banner_title)
                if (privacyPolicyRef.current) {
                    privacyPolicyRef.current.clipboard.dangerouslyPasteHTML(response.data.data.privacy_policy);
                }
                if (termsConditionsRef.current) {
                    termsConditionsRef.current.clipboard.dangerouslyPasteHTML(response.data.data.terms_conditions);
                }
                setshowLogoFile(response.data.data.logo)
                setshowfaviconFile(response.data.data.favicon)
            } else {
                toast.error()

            }
        }).catch((error) => {
            toast.error(error.data.response)
        })
    }
    useEffect(() => {
        if (!privacyPolicyRef.current) {
            const privacyPolicyQuill = new Quill('#privacyPolicyEditor', {
                theme: 'snow',
            });
            privacyPolicyRef.current = privacyPolicyQuill;
        }

        if (!termsConditionsRef.current) {
            const termsConditionsQuill = new Quill('#termsConditionsEditor', {
                theme: 'snow',
            });
            termsConditionsRef.current = termsConditionsQuill;
        }
        GetSettings()
    }, []);



    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('id', '66a8cd4b4056226f50d82bfd');
        formData.append('razor_pay_key', razorPayKey);
        formData.append('razor_pay_secret', razorPaySecret);
        formData.append('email', email);
        formData.append('phone_number', phone);
        formData.append('business_name', businessName);
        formData.append('razor_pay_status', 1);
        if (logoFile) formData.append('logo', logoFile);
        if (faviconFile) formData.append('favicon', faviconFile);
        formData.append('bottom_banner_title', BottomBannerTitle);
        formData.append('privacy_policy', privacyPolicyRef.current.root.innerHTML);
        formData.append('terms_conditions', termsConditionsRef.current.root.innerHTML);

        try {
            const response = await ApiService.manageSettings(formData);
            if (response.data.status) {
                toast.success(response.data.message);
                Swal.fire({
                    text: "Click yes to reflect changes",
                    icon: "info",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes"
                  }).then((result) => {
                    if (result.isConfirmed) {
                       window.location.reload();
                    }
                  });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error(error.response.data.message);
        }
    };
    const handleLogoChange = (event) => {
        if (event.target.files) {
            setLogoFile(event.target.files[0]);
        }
    };

    const handleFaviconChange = (event) => {
        if (event.target.files) {
            setFaviconFile(event.target.files[0]);
        }
    };
    return (
        <>
            <h1>Business Setup</h1>
            <form onSubmit={handleSubmit} className="form-container">
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="business-name">Business Name</label>
                        <input
                            type="text"
                            id="business-name"
                            value={businessName}
                            onChange={(e) => setBusinessName(e.target.value)}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <input
                            type="text"
                            id="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Bottom Banner title</label>
                        <input
                            type="text"
                            id="phone"
                            value={BottomBannerTitle}
                            onChange={(e) => setBottomBannerTitle(e.target.value)}
                            className="form-control"
                        />
                    </div>
                </div>
                {/* <div>
                    <label className="business_switch">
                        <input
                            type="checkbox"
                            onChange={() => setRazorPayChecked(!RazorPayChecked)}
                            checked={RazorPayChecked}
                            className="business_switch_input"
                        />
                        <span className="business_slider"></span>
                    </label>
                </div> */}

                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="razorPayKey">RazorPay Key</label>
                        <input
                            type="text"
                            id="razorPayKey"
                            value={razorPayKey}
                            onChange={(e) => setRazorPayKey(e.target.value)}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="razorPaySecret">RazorPay Secret Key</label>
                        <input
                            type="text"
                            id="razorPaySecret"
                            value={razorPaySecret}
                            onChange={(e) => setRazorPaySecret(e.target.value)}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="">
                    <div className="form-group">
                        <label htmlFor="privacy-policy">Privacy Policy</label>
                        <div id="privacyPolicyEditor" className="quill-editor"></div>
                    </div>
                </div>

                <div className="">
                    <div className="form-group">
                        <label htmlFor="terms-conditions">Terms and Conditions</label>
                        <div id="termsConditionsEditor" className="quill-editor"></div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="logo">Logo (Ratio 3:1)</label>
                        <input type="file" id="logo" accept="image/*" onChange={handleLogoChange} />
                        {logoFile ? <>
                            {logoFile && (
                                <div className="logo-preview">
                                    <img src={URL.createObjectURL(logoFile)} alt="Logo Preview" width={'110px'} />
                                </div>
                            )}
                        </> : <>
                            {showlogoFile && (
                                <div className="logo-preview">
                                    <img src={showlogoFile} alt="Logo Preview" width={'110px'} />
                                </div>
                            )}
                        </>}


                    </div>
                    <div className="form-group">
                        <label htmlFor="favicon">Fav Icon (Ratio 1:1)</label>
                        <input type="file" id="favicon" accept="image/*" onChange={handleFaviconChange} />
                        {faviconFile ? <>
                            {faviconFile && (
                                <div className="favicon-preview">
                                    <img src={URL.createObjectURL(faviconFile)} alt="Favicon Preview" width={'110px'} />
                                </div>
                            )}
                        </> : <>
                            {showfaviconFile && (
                                <div className="favicon-preview">
                                    <img src={showfaviconFile} alt="Favicon Preview" width={'110px'} />
                                </div>
                            )}
                        </>}


                    </div>
                </div>
                <button type="submit" className="button6">Submit</button>
            </form>
        </>
    );
};

export default Businesspage;
