import React, { useEffect, useState } from 'react';
import './order_page_all.css';
import ApiService from '../../apicontroller/ApiController';
import { toast } from 'sonner';
import { formatDate } from '../../helpers/FormatDate';

const OrderListPage = () => {
  const [dateRange, setDateRange] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({});
  const [page,setpage] = useState(1);
  const handleDateRangeChange = (event) => {
    setDateRange(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const [orders, setOrders] = useState([]);
  const getOrder = (pageNo)=>{
    ApiService.PendingorderList({
       page:pageNo
    }).then((response)=>{
      if(response.data.status === true){
        console.log(response.data)
        setOrders(response.data.data.data)
        setPagination(response.data.data.paginator)
      }else{
        toast.error(response.data.message)
      }
    }).catch((error)=>{
      console.log(error);
      toast.error(error.response.data.message)
    })
  }
  useEffect(()=>{
    getOrder(page)
  },[page])

const handlePageChange=(page)=>{
  setpage(page)
}
  return (
    <div className="order-list-page">
      <div className="order-list-filters">
        <input
          type="date"
          value={dateRange}
          onChange={handleDateRangeChange}
        />
        <input
          type="text"
          placeholder="Search by Customer Name"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <table className="order-list-table">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Order Id</th>
            <th>Order Date</th>
            <th>Customer Information</th>
            <th>Total Amount</th>
            <th>Order Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => (
            <tr key={order.id}>
              <td>{index + 1}</td>
              <td>{order._id}</td>
              <td>{formatDate(order.createdAt)}</td>
              <td>
                <div>{order.customer.name}</div>
                <div>+91{order.customer.phone}</div>
              </td>
              <td>
                <div>₹{order.order_amount}</div>
              </td>
              <td>
                <div className={`status-badge ${order.order_status === 'pending' ? 'pending' : order.order_status === 'confirmed' ? 'confirmed' : order.order_status ===  'delivered' ? 'delivered' : 'cancelled'}`}>
                {order.order_status}
                </div>
              </td>
              <td>
                <button className="action-button view">
                  <i className="bx bx-show"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination_latestTrend">
      {pagination.totalPages > 1 && (
                        <>
                         
                              <button disabled={!pagination.hasPrevPage}  onClick={() => handlePageChange(page - 1)}><i className='bx bx-left-arrow-alt' ></i></button>
                       
                            <span>Page {page} of {pagination.totalPages}</span>
                           
                              <button  disabled={!pagination.hasNextPage}  onClick={() => handlePageChange(page + 1)}><i className='bx bx-right-arrow-alt'></i></button>
                        
                        </>
                    )}
                    </div>
    </div>
  );
};

export default OrderListPage;
