import React, { useEffect, useState } from 'react';
import './category.css';
import ApiService from '../../apicontroller/ApiController';
import { useNavigate } from 'react-router-dom';

const Categorypage = () => {
    const [categories, setCategories] = useState([]);
    const [file, setFile] = useState(null);
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const handleFileUpload = (e) => {
        setFile(e.target.files[0]);
        console.log(e.target.files[0])
    }

    const getcategory = ()=>{
         ApiService.getCategory().then((response)=>{
                if(response.data.status === true){
                    setCategories(response.data.data)  
                }else{
                    console.log(response.data.message)
                }
         }).catch((error)=>console.log(error))
    }

    useEffect(()=>{
        getcategory();
    },[])

    const addCategory = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        if (file) {
            formData.append('image', file);
        }

        // Log the FormData key-value pairs
        for (let pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        ApiService.createCategory(formData)
            .then((response) => {
                console.log('Response:', response);
                if (response.data.status === true) {
                    getcategory(); // Refresh the category list
                }
            })
            .catch((error) => console.log(error));
    }

    const deletecategory = (categoryId)=>{
        ApiService.deleteCategory({
            category_id:categoryId
        }).then((response)=>{
                console.log(response.data);
                getcategory()
        }).catch((error)=>console.log(error))
    }
   
    const handlecategoryClick = (categoryid)=>{
        navigate(`/admin/category/updateCategory/${categoryid}`)
    }
    return (
        <>
            <h1>Category</h1>
            <section>
                <div>
                    <form encType='multipart/form-data' onSubmit={addCategory}>
                        <div className='form-group'>
                            <label>Category</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Add new category"
                            />
                        </div>
                        <div className='form-group'>
                            <label>Image</label>
                            <input
                                type="file"
                                onChange={handleFileUpload}
                                placeholder="Add new category"
                            />
                            {file && <div className="logo-preview">
                                <img width={'200px'} src={URL.createObjectURL(file)} alt="Logo Preview" />
                            </div>}
                        </div>
                        <button type="submit">Add</button>
                    </form>
                </div>
            </section>

            <div className="search-bar1">
                <input type="text" placeholder="Search categories" />
                <button>Search</button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>IMAGE</th>
                        <th>ACTION</th>
                    </tr>
                </thead>
                <tbody>
                {categories.map((category, index) => (
    <tr key={category._id || index}> {/* Fallback to index if _id is missing */}
        <td>{index + 1}</td> {/* Displaying index + 1 to show 1-based index */}
        <td>{category._id}</td>
        <td>{category.name}</td>
        <td><img src={category.image} alt='images' width={'150px'}/></td>
        <td>
            <button onClick={()=>deletecategory(category._id)}><i class='bx bx-trash'></i></button>
            <button onClick={()=>handlecategoryClick(category._id)}><i class='bx bx-edit-alt'></i></button>
        </td>
    </tr>
))}
                </tbody>
            </table>
        </>
    );
};

export default Categorypage;
