import React, { useEffect, useState } from 'react';
import './change.css';
import Select from 'react-select';
import ApiService from '../../apicontroller/ApiController';
import { toast } from 'sonner';
const AddProduct = () => {
  const [productName, setProductName] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [logoFile, setLogoFile] = useState(null);
  const [images, setImages] = useState([]);
  const [defaultColorImage, setDefaultColorImage] = useState(null);
  const [defaultColorName, setdefaultColorName] = useState('');
  const [defaultColorImages, setDefaultColorImages] = useState([]);
  const [colors, setColors] = useState([{ id: 1, name: '', images: [] }]);
  const [productType, setProductType] = useState('normal');
  const [features, setFeatures] = useState([{ id: 1, value: '' }]);
  const [variations, setVariations] = useState([{ id: 1, sizeName: '', price: '', stock: '' }]);
  const [productDescription, setProductDescription] = useState('');
  const [productDetails, setProductDetails] = useState('');
  const [stock, setStock] = useState('');
  const [defaultSize, setDefaultSize] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [SubcategoryOptions, setSubCategoryOptions] = useState([]);
  const handleLogoChange = (event) => {
    if (event.target.files) {
      setLogoFile(event.target.files[0]);
    }
  };
  const GetCategoryOptions = () => {
    ApiService.getCategory().then((response) => {
      if (response.data.status === true) {
        const formattedOptions = response.data.data.map((category) => {
          return { value: category._id, label: category.name };
        });
        setCategoryOptions(formattedOptions);
      } else {
        console.log(response.data.message);
      }
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    GetCategoryOptions();
  },[])
  const handleImagesChange = (event) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setImages((prevImages) => [...prevImages, ...filesArray]);
    }
  };

  const handleDefaultColorImagesChange = (event) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setDefaultColorImages((prevImages) => [...prevImages, ...filesArray]);
    }
  };

  const handleColorImagesChange = (id, event) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setColors((prevColors) =>
        prevColors.map((color) =>
          color.id === id ? { ...color, images: [...color.images, ...filesArray] } : color
        )
      );
    }
  };

  const handleColorNameChange = (id, value) => {
    setColors((prevColors) =>
      prevColors.map((color) =>
        color.id === id ? { ...color, name: value } : color
      )
    );
  };

  const addColor = () => {
    setColors((prevColors) => [
      ...prevColors,
      { id: prevColors.length + 1, name: '', images: [] },
    ]);
  };

  const removeColor = (id) => {
    setColors((prevColors) => prevColors.filter((color) => color.id !== id));
  };

  const handleFeatureChange = (id, value) => {
    setFeatures((prevFeatures) =>
      prevFeatures.map((feature) =>
        feature.id === id ? { ...feature, value } : feature
      )
    );
  };

  const addFeature = () => {
    setFeatures((prevFeatures) => [
      ...prevFeatures,
      { id: prevFeatures.length + 1, value: '' },
    ]);
  };

  const removeFeature = (id) => {
    setFeatures((prevFeatures) => prevFeatures.filter((feature) => feature.id !== id));
  };

  const handleVariationChange = (id, field, value) => {
    setVariations((prevVariations) =>
      prevVariations.map((variation) =>
        variation.id === id ? { ...variation, [field]: value } : variation
      )
    );
  };

  const addVariation = () => {
    setVariations((prevVariations) => [
      ...prevVariations,
      { id: prevVariations.length + 1, sizeName: '', price: '', stock: '' },
    ]);
  };

  const removeVariation = (id) => {
    setVariations((prevVariations) => prevVariations.filter((variation) => variation.id !== id));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const formData = new FormData();
    formData.append('name', productName);
    formData.append('price', price);
    formData.append('category_id', category);
    formData.append('sub_category_id', subCategory);
    formData.append('stock', stock);
    formData.append('default_size', defaultSize);
    formData.append('description', productDescription);
    formData.append('product_details', productDetails);
    formData.append('product_type',productType)
    features.forEach((feature, index) => {
      formData.append(`features[${index}]`, feature.value);
    });
  
    if (logoFile) formData.append('image', logoFile);
    images.forEach((file) => formData.append('images', file));
    if (defaultColorImage) formData.append('default_color_image', defaultColorImage);
    defaultColorImages.forEach((file) => formData.append('default_color_images', file));
  
    colors.forEach((color, index) => {
      formData.append(`colors[${index}][color_name]`, color.name);
      color.images.forEach((imgFile) => formData.append(`colors[${index}][color_images]`, imgFile));
    });
  
    variations.forEach((variation, index) => {
      formData.append(`sizes[${index}][size]`, variation.sizeName);
      formData.append(`sizes[${index}][size_price]`, variation.price);
      formData.append(`sizes[${index}][size_stock]`, variation.stock);
    });
  
    // // Log the formData for debugging (optional)
    // for (let pair of formData.entries()) {
    //   console.log(`${pair[0]}: ${pair[1]}`);
    // }
  
    ApiService.Addproduct(formData).then((response) => {
      if (response.data.status === true) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    }).catch((error) => {
      console.error(error);
    });
  };
  
  
  const getSubcategory = (categoryId)=>{
    // Call API to get subcategories based on selected category
    ApiService.getSubCategoryByCategory({
      category_id:categoryId
    }).then((response) => {
      if (response.data.status === true) {
        const formattedOptions = response.data.data.map((subcategory) => {
          return { value: subcategory._id, label: subcategory.name };
        });
        setSubCategoryOptions(formattedOptions);
      } else {
        console.log(response.data.message);
      }
    }).catch((error) => console.log(error));
  }
  const handleCategoryChange = (selectedOption) => {
    setCategory(selectedOption.value);
    getSubcategory(selectedOption.value)
  };
  const handleSubCategoryChange = (selectedOption)=>{
    setSubCategory(selectedOption.value)
  }
  const handleDefaultcolorImage = (e)=>{
       setDefaultColorImage(e.target.files[0])
  }
  return (
    <>
      <h1>Add New Product</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group inline">
          <div>
            <label htmlFor="productType">Product Type *</label>
            <select
              id="productType"
              className="form-control"
              value={productType}
              onChange={(e) => setProductType(e.target.value)}

            >
              <option value="">---Select---</option>
              <option value="normal">Normal</option>
              <option value="personalize">Personalize</option>
            </select>
          </div>
        </div>
        <div className="form-group inline">
          <div>
            <label htmlFor="productName">Product Name *</label>
            <input
              type="text"
              id="productName"
              className="form-control"
              placeholder='Enter Product Name'
              value={productName}
              onChange={(e) => setProductName(e.target.value)}

            />
          </div>
          <div>
            <label htmlFor="price">Price *</label>
            <input
              type="number"
              id="price"
              placeholder='Enter Price'
              className="form-control custom_number_input"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              inputMode='decimal'

            />
            <small className="form-text text-muted">Ex: 100</small>
          </div>
        </div>
        <div className="form-group inline">
          <div>
            <label htmlFor="category">Category *</label>
            <Select
              options={categoryOptions}
              placeholder="Select Categories"
              isSearchable={true}
              onChange={handleCategoryChange}
            // value={categoryOptions.filter(option => categoryId.includes(option.value))}
            />
          </div>
          <div>
            <label htmlFor="subCategory">Sub Category *</label>
            <Select
              options={SubcategoryOptions}
              placeholder="Select Categories"
              isSearchable={true}
              onChange={handleSubCategoryChange}
            // value={categoryOptions.filter(option => categoryId.includes(option.value))}
            />
          </div>
        </div>
        <div className="form-group inline">
          <div>
            <label htmlFor="image">Image (Ratio 3:1)</label>
            <input type="file" id="image" accept="image/*" onChange={handleLogoChange} />
            {logoFile && (
              <div className="image-preview">
                <img src={URL.createObjectURL(logoFile)} alt="Images Preview" />
              </div>
            )}
          </div>
          <div>
            <label htmlFor="images">Images</label>
            <input type="file" id="images" accept="image/*" multiple onChange={handleImagesChange} />
            <div className="image-preview">
              {images.map((image, index) => (
                <img key={index} src={URL.createObjectURL(image)} alt={`Preview ${index}`} />
              ))}
            </div>
          </div>
        </div>
        {productType === 'normal' && (
          <>
        <div className="form-group inline">
          <div>
            <label htmlFor="defaultColorImages">Default Color Image</label>
            <input type="file" id="defaultColorImages" accept="image/*" onChange={handleDefaultcolorImage} />
            <div className="image-preview">
            {images.map((image, index) => (
                <img key={index} src={URL.createObjectURL(image)} alt={`Preview ${index}`} />
              ))}
            </div>
          </div>
          <div>
            <label htmlFor="defaultColorImages">Default Color Images</label>
            <input type="file" id="defaultColorImages" accept="image/*" multiple onChange={handleDefaultColorImagesChange} />
            <div className="image-preview">
              {defaultColorImages.map((image, index) => (
                <img key={index} src={URL.createObjectURL(image)} alt={`Preview ${index}`} />
              ))}
            </div>
          </div>
        </div>
      
            {colors.map((color) => (
              <div key={color.id} className="color_group">
                <div >
                  <label htmlFor={`colorName-${color.id}`}>Color Name</label>
                  <input
                    type="text"
                    id={`colorName-${color.id}`}
                    className="form-control"
                    value={color.name}
                    placeholder='Enter Color name'
                    onChange={(e) => handleColorNameChange(color.id, e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor={`colorImages-${color.id}`}>Color Images</label>
                  <input
                    type="file"
                    id={`colorImages-${color.id}`}
                    accept="image/*"
                    multiple
                    onChange={(e) => handleColorImagesChange(color.id, e)}
                  />
                  <div className="image-preview">
                    {color.images.map((image, index) => (
                      <img key={index} src={URL.createObjectURL(image)} alt={`Preview ${index}`} />
                    ))}
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => removeColor(color.id)}
                >
                  Remove Color
                </button>
              </div>
            ))}
            <button type="button" className="btn btn-secondary" onClick={addColor}>
              Add Color
            </button>
          </>
        )}

        <div className="form-group inline">
          <div className='features_group'>
            <label htmlFor="features">Features</label>
            {features.map((feature) => (
              <div key={feature.id} className="features_sub_group">
                <input
                  type="text"
                  className="form-control"
                  value={feature.value}
                  placeholder='Enter Features'
                  onChange={(e) => handleFeatureChange(feature.id, e.target.value)}
                />
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => removeFeature(feature.id)}
                >
                  Remove Feature
                </button>
              </div>
            ))}
            <button type="button" className="btn btn-secondary" onClick={addFeature}>
              Add Feature
            </button>
          </div>
        </div>
        <div className="form-group inline">
          <div>
            <label htmlFor="productDescription">Product Description</label>
            <textarea
              id="productDescription"
              className="form-control"
              value={productDescription}
              onChange={(e) => setProductDescription(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="productDetails">Product Details</label>
            <textarea
              id="productDetails"
              className="form-control"
              value={productDetails}
              onChange={(e) => setProductDetails(e.target.value)}
            />
          </div>
        </div>
        <div className='size_group'>
              <label htmlFor="stock">Stock *</label>
              <input
                type="number"
                id="stock"
                className="form-control custom_number_input"
                value={stock}
                placeholder='Enter stock'
                onChange={(e) => setStock(e.target.value)}

              />
            </div>
        {productType === 'normal' && (
          <div className="form-group inline">
            
            <div>
              <label htmlFor="defaultSize">Default Size</label>
              <input
                type="text"
                id="defaultSize"
                className="form-control"
                placeholder='Enter default size of product'
                value={defaultSize}
                onChange={(e) => setDefaultSize(e.target.value)}
              />
            </div>
          </div>
        )}
        {productType === 'normal' && (
          <div className="form-group inline">
            <div className='variation_group'>
              <label>Variations</label>
              {variations.map((variation) => (
                <div key={variation.id} className="variation_sub_group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Size Name"
                    value={variation.sizeName}
                    onChange={(e) => handleVariationChange(variation.id, 'sizeName', e.target.value)}
                  />
                  <input
                    type="number"
                    className="form-control custom_number_input"
                    placeholder="Price"
                    value={variation.price}
                    onChange={(e) => handleVariationChange(variation.id, 'price', e.target.value)}
                  />
                  <input
                    type="number"
                    className="form-control custom_number_input"
                    placeholder="Stock"
                    value={variation.stock}
                    onChange={(e) => handleVariationChange(variation.id, 'stock', e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => removeVariation(variation.id)}
                  >
                    Remove Variation
                  </button>
                </div>
              ))}
              <button type="button" className="btn btn-secondary" onClick={addVariation}>
                Add Variation
              </button>
            </div>
          </div>
        )}
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </>
  );
};

export default AddProduct;
