import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import ApiService from '../../apicontroller/ApiController';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
export default function UpdateCatalog() {
    const [productOptions, setProductOptions] = useState([]);
    const [productId, setProductId] = useState([]);
    const [image,setImage] = useState(null);
    const [content,setcontent] = useState('');
    const [title,setTitle] = useState('');
    const [file,setFile] = useState('');
    const {catalogId} =  useParams();
    const navigate = useNavigate();
    const handleImageChange =(e)=>{
        setImage(e.target.files[0])
    }
    const GetProductOptions = () => {
        ApiService.getProductDropdown().then((response) => {
            if (response.data.status === true) {
                const formattedOptions = response.data.data.map((category) => {
                    return { value: category._id, label: category.name };
                });
                setProductOptions(formattedOptions);
            } else {
                console.error(response.data);
            }
        }).catch((error) => console.log(error));
    };
    useEffect(()=>{
        GetProductOptions()
    },[]);
const handleProductChange =(selectedOptions)=>{
    setProductId(selectedOptions.map((option) => option.value));
}

const fetchCatalogs = () => {
ApiService.getCatelogs()    
        .then(response => {
              if (response.data.status === true) {
                const catalogList = response.data.data.data;
                const catalog = catalogList.find(catalog=>catalog._id===catalogId);
                setTitle(catalog.title);
                setcontent(catalog.content);
                setProductId(catalog.product_ids.map((id)=> id));
                setFile(catalog.image)
              } else {
                  console.error(response.data);
              }
 
        })
        .catch(error => {
            console.error('Error fetching catalogs:', error);
        });
};
useEffect(()=>{
    fetchCatalogs()
},[]);

const updateCatalog=(e)=>{
    e.preventDefault()
    const formData =  new FormData();
    formData.append('title',title);
    formData.append('content',content);
    formData.append('image',image);
    formData.append('catalog_id',catalogId);
    productId.forEach((id, index) => {
        formData.append(`product_ids[${index}]`, id);
    });
    ApiService.editCatalogs(formData).then((response)=>{
            if(response.data.status === true){
                console.log(response.data);
                toast.success(response.data.message);
                navigate('/admin/catalog/cataloglist')
            }else{
                console.error(response.data);
            }
    }).catch((error)=>{
        console.log(error)
    })
}
  return (
    <>
        <section>
        <form onSubmit={updateCatalog}>

<div>
<label htmlFor="productName">Select Products</label>
               <Select
                   options={productOptions}
                   placeholder="Select Products"
                   isSearchable={true}
                   isMulti={true}
                   onChange={handleProductChange}
                   value={productOptions.filter(option => productId.includes(option.value))}
               />
</div>
<div className='form-group'>
<label>Title</label>
<input type='text' className='form-control' id='title' value={title} onChange={(e)=>setTitle(e.target.value)} placeholder='Enter Title' required />
</div>
<div className='form-group'>
<label>Content</label>
   <textarea className='form-control' id='content' value={content} onChange={(e)=>setcontent(e.target.value)} placeholder='Enter Content' required />
</div>
<div className='form-group'>
               <label>Catalog Image</label>
               <input type='file' onChange={handleImageChange} />
               {image ?
                            <>
                                {image && (
                                    <div className='logo-preview'>
                                        <img src={URL.createObjectURL(image)} alt='images' width={'200px'} />
                                    </div>
                                )}
                            </> :
                            <>
                            {file && (
                                    <div className='logo-preview'>
                                        <img src={file} alt='images' width={'200px'} />
                                    </div>
                                )}
                            </>}

           </div>
           <div>
               <button type='submit' className='btn btn-primary'>Submit</button>
           </div>
</form>
        </section>
    </>
  )
}

