import React, { useEffect, useState } from 'react'
import ApiService from '../../apicontroller/ApiController';
import { useParams } from 'react-router-dom';

export default function UpdateCategory() {
    const [image,setImage] = useState(null);
    const [file, setFile] = useState(null);
    const [name, setName] = useState('');
     const categoryId = useParams()
    const handleFileUpload = (e) => {
        setFile(e.target.files[0]);
    }

    const getcategory = ()=>{
         ApiService.getCategory().then((response)=>{
                if(response.data.status === true){
                 
                 const categorylist =  response.data.data;
                 const categoryEdit  = categorylist.find((category)=> category._id === categoryId.categoryId)
                 console.log(categoryEdit)
                    setName(categoryEdit.name ?? '');
                    setImage(categoryEdit.image ?? '');
                }else{
                    console.log(response.data.message)
                }
         }).catch((error)=>console.log(error))
    }

    useEffect(()=>{
        getcategory();
    },[]);


    const updateCategory = (e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('image', file);
        formData.append('category_id', categoryId.categoryId);
        ApiService.updateCategory(formData).then((response)=>{
                if(response.data.status === true){
                    console.log(response.data.message)
                }else{
                    console.log(response.data.message)
                }
         }).catch((error)=>console.log(error))
    }
  return (
    <>
        <section>
                <div>
                    <form encType='multipart/form-data' onSubmit={updateCategory}>
                        <div className='form-group'>
                            <label>Category</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Add new category"
                            />
                        </div>
                        <div className='form-group'>
                            <label>Image</label>
                            <input
                                type="file"
                                onChange={handleFileUpload}
                                placeholder="Add new category"
                            />
                            {file ? <>
                                {file && <div className="logo-preview">
                                <img width={'200px'} src={URL.createObjectURL(file)} alt="Logo Preview" />
                            </div>}
                            </> :
                            <>
                            {image && <div className="logo-preview">
                                <img width={'200px'} src={image} alt="Logo Preview" />
                            </div>}
                            </>}

                        </div>
                        <button type="submit">Add</button>
                    </form>
                </div>
            </section>
    </>
  )
}
