import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ApiService from '../../apicontroller/ApiController';
import { useNavigate } from 'react-router-dom';


const Productpage = () => {
    const [SubCategoryList,setSubCategoryList] = useState([])
    const [image, setImage] = useState(null);
    const [options,setOptions] = useState([]);
    const [name,setName] =  useState();
    const [catgoryId,setCategoryid] = useState();
    const navigate =  useNavigate();

    const handleImageChange = (e) => {
        // if (e.target.files) {
            setImage(e.target.files[0]);
            console.log(e.target.files[0])
        // }
    };
    const getSubCategory = ()=>{
        ApiService.getSubCategory().then((response)=>{
            setSubCategoryList(response.data.data);
        }).catch((error)=>console.log(error))
    }
    const getcategory = ()=>{
        ApiService.getCategory().then((response)=>{
               if(response.data.status === true){
                const formattedOptions = response.data.data.map((category)=>{
                    return {value: category._id, label: category.name}
                })
                setOptions(formattedOptions)  
               }else{
                   console.log(response.data.message)
               }
        }).catch((error)=>console.log(error))
   }

   useEffect(()=>{
       getcategory();
       getSubCategory();
   },[]);
   const handleCategoryChange = (selectedOption) => {
    setCategoryid(selectedOption.value);
};
   const addsubcategory = (e)=>{
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('category_id', catgoryId);
    formData.append('image', image);
    ApiService.createSubCategory(formData).then((response)=>{
        console.log(response.data)
        if(response.data.status === true){
            getSubCategory()
        }
    }).catch((error)=> console.log(error))
   }

   const deletecategory = (subCategoryId)=>{
    console.log(subCategoryId)
    ApiService.deleteCategory({
        category_id:subCategoryId
    }).then((response)=>{
        console.log(response.data);
        if(response.data.status === true){
            getSubCategory()
        }
    }).catch((error)=>console.log(error))
   }
        const handleupdatesubcategory = (subcategoryId)=>{
            navigate(`/admin/category/subcategory/updatesubCategory/${subcategoryId}`)
        }
    return (
        <>
            <div className="add-new-sub-category">
                <h2>Add New Sub Category</h2>
                <form onSubmit={addsubcategory}>
                    <div>
                        <label htmlFor="main-category">Main Category:</label>
                        <Select
                            options={options}
                            placeholder="Select Category"
                            isSearchable={true}
                            onChange={handleCategoryChange}
                            // value={catgoryId}
                        />
                    </div>
                    <div>
                        <label htmlFor="sub-category">Sub Category:</label>
                        <input
                            type="text"
                            id="sub-category"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="image">Image (Ratio 1:1):</label>
                        <input
                            type="file"
                            id="image"
                            accept="image/*"
                            onChange={handleImageChange}
                        />
                    </div>
                    <button type="submit">Save</button>
                </form>
            </div>
            <div className="sub-category-list">
                <h2>Sub Category List</h2>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>ID</th>
                               
                                <th>Sub Category</th>
                                <th>Image</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {SubCategoryList.map((item, index) => (
                                <tr key={item._id}>
                                    <td>{index + 1}</td>
                                    <td>{item._id}</td>
                                    <td>{item.name}</td>
                                    <td><img src={item.image} alt='images' width={'150px'}/></td>
                                    <td>
                                        <button className="edit-button" onClick={()=>handleupdatesubcategory(item._id)}><i class='bx bx-edit-alt'></i></button>
                                        <button className="delete-button" onClick={()=>deletecategory(item._id)}><i class='bx bx-trash'></i></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Productpage;
