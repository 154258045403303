import React, { useEffect, useState } from 'react';
import { ShoppingBagOutlined } from '@mui/icons-material';
import './product.css';
import ApiService from '../../apicontroller/ApiController';
import { useNavigate } from 'react-router-dom';

const Productpage = () => {
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState({});
  const [totalPages,setTotalPages] = useState();
  const navigate = useNavigate()
  const [page,setpage] = useState(1)
   useEffect(()=>{
     ApiService.getProduct({
      page:page
     }).then((response)=>{
      if(response.data.status === true){
        console.log(response.data.data)
        setProducts(response.data.data.data)
            setTotalPages(response.data.totalPages);
      }else{
        console.log(response.data)
      } 
     })
   },[page]);
   const handlePageChange =(page)=>{
    setpage(page)
   }
    const handleEdit = (productId)=>{
      navigate(`/admin/product/updateproduct/${productId}`)
    }
  return (
    <>
      <h1>
        <ShoppingBagOutlined style={{ marginRight: '10px' }} />
        Product List
      </h1>
      <div className="search-bar">
        <input type="text" placeholder="Search here" />
        <button>Search</button>
      </div>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Name</th>
            <th>Product details</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={product.id}>
              <td>{index + 1}</td>
              <td>
                {product.image ? (
                  <img src={product.image} alt={product.name} width={'100px'}/>
                ) : (
                  'No image'
                )}
              </td>
              <td>{product.name}</td>
              <td>{product.product_details}</td>
              <td>{product.price}</td>
              <td>{product.stock}</td>
              <td>
                <button onClick={()=>{handleEdit(product._id)}}><i class='bx bx-edit-alt'></i></button>
                <button><i class='bx bx-trash'></i></button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
                    {/* {totalPages > 1 && ( */}
                        <>
                            {/* {pagination.hasPrevPage && ( */}
                                <button onClick={() => handlePageChange(page - 1)}>Prev</button>
                            {/* )} */}
                            <span>Page {page} of {totalPages}</span>
                            {/* {pagination.hasNextPage && ( */}
                                <button onClick={() => handlePageChange(page + 1)}>Next</button>
                            {/* )} */}
                        </>
                    {/* )} */}
                </div>
    </>
  );
};

export default Productpage;
