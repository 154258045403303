import React, { useState, useEffect } from 'react';
import ApiService from '../../apicontroller/ApiController';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';

export default function VideoBanner() {
    const [video, setVideo] = useState(null);
    const [videoUrl, setVideoUrl] = useState('');
    const [banners,setBanners] = useState([]);
    const [title,setTitle] =  useState('');
    const navigate = useNavigate();
    const handleVideoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (videoUrl) {
                URL.revokeObjectURL(videoUrl); // Clean up previous URL
            }
            setVideo(file);
            setVideoUrl(URL.createObjectURL(file));
        }
    };

    // Clean up object URL to avoid memory leaks
    useEffect(() => {
        return () => {
            if (videoUrl) {
                URL.revokeObjectURL(videoUrl);
            }
        };
    }, [videoUrl]);

    const getVideoBanner = ()=>{
         ApiService.getVideoBanner().then((response)=>{
                 if(response.data.status === true){
                     toast.success(response.data.message);
                     setBanners(response.data.data);
                     console.log(response.data)
                 }else{
                    toast.error(response.data.message);
                 }
         }).catch((error)=> console.error(error))
    }
      useEffect(()=>{
        getVideoBanner();
      },[]);

      const createVideoBanner = (e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('video', video);

        ApiService.createVideoBanner(formData).then((response)=>{
            if(response.data.status === true){
                toast.success(response.data.message);
                getVideoBanner();
            }else{
                toast.error(response.data.message);
            }
        }).catch((error)=> toast.error(error))
      }

      const deleteVideoBanner = (bannerId) =>{
        ApiService.deleteVideoBanner({
            banner_id : bannerId
        }).then((response)=>{
            if(response.data.status === true){
                toast.success(response.data.message);
                getVideoBanner();
            }else{
                toast.error(response.data.message);
            }
        }).catch((error)=> toast.error(error))
      }

      const handleEdit = (bannerId)=>{
        navigate(`/admin/banner/videobanner/updatevideobanner/${bannerId}`)
      }
    return (
        <>
            <section>
                <h2>Featured Video</h2>
                <form onSubmit={createVideoBanner}>
                <div className='form-group'>
                    <input type='text' placeholder='Enter Title' value={title} onChange={(e)=> setTitle(e.target.value)}  />

                </div>
                <div className='form-group'>
                    <input type='file' accept="video/*" onChange={handleVideoChange} />
                    {video && (
                        <video key={videoUrl} width={'300px'} controls loop>
                            <source src={videoUrl} type={video.type} />
                            Your browser does not support the video tag.
                        </video>
                    )}
                </div>
                <div>
                    <button type="submit" >Submit</button>
                </div>
                </form>

                <section>
                    <table>
                        <thead>
                            <tr>
                            <th>S.No.</th>
                            <th>Id</th>
                            <th>Title</th>
                            <th>Video</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {banners.map((banner, index)=>(
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{banner._id}</td>
                                <td>{banner.title}</td>
                                <td>
                                    <video width={'150px'} controls loop>
                                        <source src={banner.video}/>
                                    </video>
                                </td>
                                <td>
                                            <button onClick={()=>handleEdit(banner._id)}>
                                                <i class='bx bx-edit-alt'></i>
                                            </button>
                                            <button onClick={()=> deleteVideoBanner(banner._id)} >
                                                <i class='bx bx-trash'></i>
                                            </button>
                                        </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            </section>
        </>
    );
}
