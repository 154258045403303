import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faBicycle,faTrashAlt, faCartPlus, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import './dashboard.css';
import ApiService from '../../apicontroller/ApiController';
import order from '../../assets/images/order.png';
import users from '../../assets/images/user.png';
import sales from '../../assets/images/sales.png';
import carts from '../../assets/images/cart.png';
import category from '../../assets/images/category.png';
import products from '../../assets/images/products.png';
const InstallationPage = () => {
  const [user, setUser] = useState({});

  const getUserProfile = () => {
    ApiService.getUserprofile()
      .then((response) => {
        if (response.data.status === true) {
          setUser(response.data.data);
        } else {
          console.error(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="installation-page">
      <h1>Welcome, {user.name}.</h1>
      <div className="dashboard">
        <div className="card_dashboard">
          <h2>Orders</h2>
          <div className="card_dashboard-content">
            <span className="count">13</span>
            <span className="icon">
             <img src={order} alt='order' width={'50px'}/>
            </span>
          </div>
        </div>

        <div className="card_dashboard">
          <h2>Users</h2>
          <div className="card_dashboard-content">
            <span className="count">133</span>
            <span className="icon">
            <img src={users} alt='user' width={'50px'}/>
            </span>
          </div>
        </div>

        <div className="card_dashboard">
          <h2>Sales</h2>
          <div className="card_dashboard-content">
            <span className="count">Rs. 8,802</span>
            <span className="icon">
            <img src={sales} alt='user' width={'50px'}/>
            </span>
          </div>
        </div>

        <div className="card_dashboard">
          <h2>Total Category</h2>
          <div className="card_dashboard-content">
            <span className="count">5</span>
            <span className="icon">
            <img src={category} alt='user' width={'50px'}/>
            </span>
          </div>
        </div>

        <div className="card_dashboard">
          <h2>Total Cart Item</h2>
          <div className="card_dashboard-content">
            <span className="count">2</span>
            <span className="icon">
            <img src={carts} alt='user' width={'50px'}/>
            </span>
          </div>
        </div>

        <div className="card_dashboard">
          <h2>Total Products</h2>
          <div className="card_dashboard-content">
            <span className="count">1</span>
            <span className="icon">
            <img src={products} alt='user' width={'50px'}/>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallationPage;
