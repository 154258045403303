import React, { useState, useEffect } from 'react';
import ApiService from '../../apicontroller/ApiController';
import { toast } from 'sonner';
import './latesttrend.css';
export default function LatestTrend() {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [initialSelectedProducts, setInitialSelectedProducts] = useState([]);
  const [page,setpage] = useState(1);
  const [totalPages,setTotalPages] = useState();
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await ApiService.getProduct({
            page:page
           });
           if(response.data.status === true){

               setProducts(response.data.data.data);
               setTotalPages(response.data.data.totalPages);
            }else{
                setpage(page - 1)
            }
      
      } catch (error) {
        setpage(page - 1)
      }
    };

    const getLatestTrend = async () => {
      try {
        const response = await ApiService.getlatestTrend();
        if (response.data.status === true) {
          const existingProducts = response.data.data.products.map((product) => product._id);
          setSelectedProducts(existingProducts);
          setInitialSelectedProducts(existingProducts); // Store initial state for comparison
        } else {
          toast.error(response.data.message);

        }
      } catch (error) {
        console.error('Error fetching latest trend products', error);
        toast.error('Failed to fetch latest trend products.');
      }
    };

    fetchProducts();
    getLatestTrend();
  }, [page]);

  const handleSelectProduct = (productId) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(productId)
        ? prevSelected.filter((id) => id !== productId)
        : [...prevSelected, productId]
    );
  };

  const handleUpdateLatestTrend = async () => {
    const productsToAdd = selectedProducts.filter(id => !initialSelectedProducts.includes(id));
    const productsToRemove = initialSelectedProducts.filter(id => !selectedProducts.includes(id));

    try {
      if (productsToAdd.length > 0) {
        const addResponse = await ApiService.addLatestTrend({
          product_ids: productsToAdd,
        });
        if (addResponse.data.status !== true) {
          throw new Error(addResponse.data.message);
        }
      }

      if (productsToRemove.length > 0) {
        const removeResponse = await ApiService.RemoveLatestTrend({
          productIds: productsToRemove,
        });
        if (removeResponse.data.status !== true) {
          throw new Error(removeResponse.data.message);
        }
      }

      toast.success('Latest Trend section updated.');
    } catch (error) {
      console.error('Error updating Latest Trend', error);
      toast.error(error.message || 'Failed to update Latest Trend.');
    }
  };
  const handlePageChange =(page)=>{
    console.log(page)
    if(page <= 0){
        setpage(1)
    }
    if(page > 0){
        
        setpage(page)
    }
   }
  return (
    <div>
      <table className="order-list-table">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Product Image</th>
            <th>Product Name</th>
            <th>Select Product</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={product._id}>
              <td>{index + 1}</td>
              <td>
                <img src={product.image} alt={product.name} style={{ width: '50px', height: '50px' }} />
              </td>
              <td>{product.name}</td>
              <td>
                <input
                  type="checkbox"
                  checked={selectedProducts.includes(product._id)}
                  onChange={() => handleSelectProduct(product._id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination_latestTrend">
                    {/* {totalPages > 1 && ( */}
                        <>
                            {/* {pagination.hasPrevPage && ( */}
                                <button onClick={() => handlePageChange(page - 1)}><i className='bx bx-left-arrow-alt' ></i></button>
                            {/* )} */}
                            <span>Page {page} of {totalPages}</span>
                            {/* {pagination.hasNextPage && ( */}
                                <button onClick={() => handlePageChange(page + 1)}><i className='bx bx-right-arrow-alt'></i></button>
                            {/* )} */}
                        </>
                    {/* )} */}
                </div>
                <div className='update_latest_trend_btn'>
            <button onClick={handleUpdateLatestTrend}>Update Latest Trend</button>
                </div>
    </div>
  );
}
