import React, { useEffect, useRef, useState } from 'react'
import ApiService from '../../apicontroller/ApiController'
import { useNavigate } from 'react-router-dom';

export default function BottomBanner() {
    const [bottomBanner, setbottomBanner] = useState([]);
    const [image, setimage] = useState(null);
    const inputFileRef = useRef(null);
    const navigate = useNavigate();
    const getBottomBanner = () => {
        ApiService.getBottomBanner().then((response) => {
            if (response.data.status === true) {
                setbottomBanner(response.data.data)
            } else {
                setbottomBanner([])
            }
        }).catch((error) => console.log(error))
    }

    const createBottomBanner = (e) => {
         e.preventDefault();
         if(!image){
           return alert('Please select an image');
            
         }
        const formData = new FormData();
        formData.append('image', image);
        ApiService.createBottomBanner(formData).then((response) => {
            if (response.data.status === true) {
                setimage('')
                getBottomBanner();
            } else {
                alert('Failed to Create Bottom Banner')
            }
        }).catch((error) => console.log(error))
    }
    const handleImageChange = (e) => {
        setimage(e.target.files[0])

    }

    const handleEdit=(bannerId)=>{
   navigate(`/admin/banner/bottombanner/updatebottombanner/${bannerId}`)
    }
    const handleDelete = (bannerId)=>{
        ApiService.deleteBottomBanner({
            banner_id:bannerId
        }).then((response)=>{
            if(response.data.status===true){
                getBottomBanner();
            }else{
                alert('Failed to delete Bottom Banner')
            }
        }).catch((error)=> console.log(error))
    }
    useEffect(() => {
        getBottomBanner()
    }, [])
    return (
        <>
            <section>
                <h2>Bottom Banner</h2>
                <div>
                    <form onSubmit={createBottomBanner}>
                        <div className='form-group'>
                            <label>Bottom Banner</label>
                            <input type='file' onChange={handleImageChange} ref={inputFileRef} />
                            {image && (
                                <div className='logo-preview'>
                                    <img src={URL.createObjectURL(image)} alt='images' width={'200px'} />
                                </div>
                            )}
                        </div>

                        <div>
                            <button type='submit'>Submit</button>
                        </div>
                    </form>
                </div>
                <hr />
                <section>
                    <h4>
                        Banner List
                    </h4>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>ID</th>
                                <th>Image</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bottomBanner.map((banner, index) => {
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{banner._id}</td>
                                        <td><img src={banner.image} width={'150px'} alt='Placeholder' /></td>
                                        <td>
                                            <button onClick={()=>handleEdit(banner._id)}>
                                                <i class='bx bx-edit-alt'></i>
                                            </button>
                                            <button onClick={()=> handleDelete(banner._id)} >
                                                <i class='bx bx-trash'></i>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>

                    </table>
                </section>
            </section>
        </>
    )
}
