import UserPageLayout from "../pages/UserManagement/UserPageLayout";
import User from "../pages/UserManagement/User";
import ChangelogPage from "../pages/Product/ChangelogPage";
import ComponentPageLayout from "../pages/Bookings/ComponentPageLayout";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import CategoryOutlined from '@mui/icons-material/CategoryOutlined';
import PaymentOutlined from '@mui/icons-material/PaymentOutlined';
import BusinessOutlined from '@mui/icons-material/BusinessOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import Productpage from "../pages/Product/Productpage";
import ProductPageLayout from "../pages/Product/ProductPageLayout";
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { CampaignOutlined } from '@mui/icons-material';
import { LocalOfferOutlined } from '@mui/icons-material';
import { ShoppingBagOutlined } from '@mui/icons-material';
import { MenuOpen } from '@mui/icons-material';
import Ongoing from "../pages/Bookings/Ongoing";
import Completed from "../pages/Bookings/Completed";
import Cancelled from "../pages/Bookings/Cancelled";
import All from "../pages/Bookings/All";
import Banner from "../pages/Banner/BannerPage";
import Dashboard1 from "../pages/DashboardPage/Dashboard";
import DocumentationPage from "../pages/documentation/DocumentationPage";
import Coupon from "../pages/Coupon/CouponPage";
import CouponList from "../pages/Coupon/CouponList";
import CategoryPageLayout from "../pages/Category/CategoryPageLayout";
import CategoryPage from "../pages/Category/CategoryPage";
import Subcategory from "../pages/Category/Subcategory";
import Paymentpage from "../pages/Payment/Paymentpage";
import Businesspage from "../pages/Business/BusinessPage";
import UpdateCategory from "../pages/Category/UpdateCategory";
import UpdateSubCategory from "../pages/Category/UpdateSubCategory";
import UpdateBanner from "../pages/Banner/UpdateBanner";
import BannerLayout from "../pages/Banner/BannerLayout";
import BottomBanner from "../pages/Banner/BottomBanner";
import UpdateBottomBanner from "../pages/Banner/UpdateBottomBanner";
import VideoBanner from "../pages/Banner/VideoBanner";
import UpdateVideoBanner from "../pages/Banner/UpdateVideoBanner";
import CouponPageLayout from "../pages/Coupon/CouponPageLayout";
import Cataloglayout from "../pages/catalogs/Cataloglayout";
import AddCatalog from "../pages/catalogs/AddCatalog";
import ListCatalog from "../pages/catalogs/ListCatalog";
import UpdateCatalog from "../pages/catalogs/UpdateCatalog";
import UpdateCoupon from "../pages/Coupon/UpdateCoupon";
import UpdateProduct from "../pages/Product/UpdateProduct";
import LatestTrend from "../pages/latesttrend/LatestTrend";
import OrderDetails from "../pages/Bookings/OrderDetails";
import Delivered from '../pages/Bookings/Delivered';
const appRoutes = [
  {
    index: true,
    element: <Dashboard1 />,
  },
  {
    path: "/admin",
    element: <Dashboard1 />,
    sidebarProps: {
      displayText: "Dashboard",
      icon: <DashboardOutlinedIcon />,
    },
  },
  {
    path: "/admin/user-management",
    element: <UserPageLayout />,
  
    sidebarProps: {
      displayText: "User Management",
      icon: <PeopleOutlineOutlinedIcon />,
    },
    child: [
      {
        path: "/admin/user-management/User",
        element: <User />,
        state: "dashboard.default",
        sidebarProps: {
          displayText: "User",
        },
      },
    ],
  },
  {
    path: "/admin/orders",
    element: <ComponentPageLayout />,
  
    sidebarProps: {
      displayText: "Orders",
      icon: <AppsOutlinedIcon />,
    },
    child: [
      {
        path: "/admin/orders/all",
        element: <All />,
      
        sidebarProps: {
          displayText: "All",
        },
      },
      {
        path: "/admin/orders/ongoing",
        element: <Ongoing />,

        sidebarProps: {
          displayText: "Pending",
        },
      },
      {
        path: "/admin/orders/cancelled",
        element: <Cancelled />,
        sidebarProps: {
          displayText: "Cancelled",
        },
      },
      {
        path: "/admin/orders/confirmed",
        element: <Completed />,
        sidebarProps: {
          displayText: "Confirmed",
        },
      },
      {
        path: "/admin/orders/delivered",
        element: <Delivered />,
        sidebarProps: {
          displayText: "Delivered",
        },
      },
      {
        path: "/admin/orders/:orderId",
        element: <OrderDetails />,
      },
 
    ],
  },
  // {
  //   path: "/admin/documentation",
  //   element: <DocumentationPage />,
  //   sidebarProps: {
  //     displayText: "Mail Config",
  //     icon: <ArticleOutlinedIcon />,
  //   },
  // },
  {
    path: "/admin/banner",
    element: <BannerLayout />,
    sidebarProps: {
      displayText: "Banner",
      icon: <CampaignOutlined />,
    },
    child:[
      {
        path: "/admin/banner",
        element: <Banner />,
        sidebarProps: {
          displayText: "Top Banner",
        },
      },
      {
        path:"/admin/banner/updatebanner/:bannerId",
        element:<UpdateBanner/>
      },
      {
        path: "/admin/banner/bottombanner",
        element: <BottomBanner />,
        sidebarProps: {
          displayText: "Bottom Banner",
        },
      },
      {
        path:"/admin/banner/bottombanner/updatebottombanner/:bannerId",
        element:<UpdateBottomBanner/>
      },
      {
        path: "/admin/banner/videobanner",
        element: <VideoBanner />,
        sidebarProps: {
          displayText: "Video Banner",
        },
      },
      {
        path:"/admin/banner/videobanner/updatevideobanner/:bannerId",
        element:<UpdateVideoBanner/>
      }
    ]
  },
  {
    path: "/admin/coupon",
    element: <CouponPageLayout />,
    sidebarProps: {
      displayText: "Coupon",
      icon: <LocalOfferOutlined />,
    },
    child: [
      {
        path: "/admin/coupon/couponpage",
        element: <Coupon />,
        sidebarProps: {
          displayText: "Coupon",
        },
      },
      {
        path: "/admin/coupon/couponlist",
        element: <CouponList />,
        sidebarProps: {
          displayText: "Coupon List",
        },
      },
      {
        path: "/admin/coupon/updatecoupon/:couponId",
        element: <UpdateCoupon />,
      }
    ],
  },
  {
    path: "/admin/catalog",
    element: <Cataloglayout />,
    sidebarProps: {
      displayText: "Catalog",
      icon: <MenuOpen />,
    },
    child: [
      {
        path: "/admin/catalog/catalogpage",
        element: <AddCatalog />,
        sidebarProps: {
          displayText: "Add Catalog",
        },
      },
      {
        path: "/admin/catalog/cataloglist",
        element: <ListCatalog/>,
        sidebarProps: {
          displayText: "Catalog List",
        },
      },
      {
        path: "/admin/catalog/UpdateCatalog/:catalogId",
        element: <UpdateCatalog/>,
      }
    ],
  },
  {
    path: "/admin/product",
    element: <ProductPageLayout />,
    sidebarProps: {
      displayText: "Product",
      icon: <ShoppingBagOutlined />,
    },
    child: [
      {
        path: "/admin/product/productpage",
        element: <Productpage />,
        sidebarProps: {
          displayText: "List",
        },
      },
      {
        path: "/admin/product/changelog",
        element: <ChangelogPage />,
        sidebarProps: {
          displayText: "Add New",
        },
      },
      {
        path: "/admin/product/updateproduct/:productId",
        element: <UpdateProduct />,
      },
    ],
  },
  {
    path: "/admin/category",
    element: <CategoryPageLayout />,
    sidebarProps: {
      displayText: "Category",
      icon: <CategoryOutlined />,
    },
    child: [
      {
        path: "/admin/category/categorypage",
        element: <CategoryPage />,
        sidebarProps: {
          displayText: "Category",
        },
      },
      {
        path: "/admin/category/updateCategory/:categoryId",
        element: <UpdateCategory />,
      },
      {
        path: "/admin/category/subcategory",
        element: <Subcategory />,
        sidebarProps: {
          displayText: "Sub Category",
        },
      },
      {
        path: "/admin/category/subcategory/updatesubCategory/:subCategoryId",
        element: <UpdateSubCategory />,
      },
    ],
  },
  {
    path: "/admin/latestTrend",
    element: <LatestTrend />,
    sidebarProps: {
      displayText: "Latest Trend",
      icon: <BusinessOutlined />,
    },
  },
  // {
  //   path: "/admin/payment",
  //   element: <Paymentpage />,
  //   sidebarProps: {
  //     displayText: "Payment Methods",
  //     icon: <PaymentOutlined />,
  //   },
  // },
  {
    path: "/admin/business",
    element: <Businesspage />,
    sidebarProps: {
      displayText: "Business",
      icon: <BusinessOutlined />,
    },
  },
];

export default appRoutes;
