import {  HashRouter, Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes/routes";
import Login from "./pages/auth/Login";
import { useAuth } from "./context/AuthContext";
import { Toaster } from "sonner";

function App() {
  const {token} = useAuth();
  return (
    <HashRouter>
    <Toaster richColors position="top-right"/>
      <Routes>
      <Route path="/" element={token ? <Navigate to="/admin" /> : <Login />} />
        <Route path="/admin" element={<MainLayout />}>
          {routes}
        </Route>
       
      </Routes>
    </HashRouter>
  );
}

export default App;
