import React, { useEffect, useState } from 'react';
import './Banner.css';
import Select from 'react-select';
import ApiService from '../../apicontroller/ApiController';
import { useNavigate } from 'react-router-dom';

const Bannerpage = () => {
    const [banners, setBanners] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [productId, setProductId] = useState([]);
    const [categoryId, setCategoryId] = useState([]);
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [isProduct, setIsProduct] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        GetProductOptions();
        GetCategoryOptions();
        getBanners();
    }, []);

    const GetProductOptions = () => {
        ApiService.getProductDropdown().then((response) => {
            if (response.data.status === true) {
                const formattedOptions = response.data.data.map((category) => {
                    return { value: category._id, label: category.name };
                });
                setProductOptions(formattedOptions);
            } else {
                console.error(response.data);
            }
        }).catch((error) => console.log(error));
    };

    const GetCategoryOptions = () => {
        ApiService.getCategory().then((response) => {
            if (response.data.status === true) {
                const formattedOptions = response.data.data.map((category) => {
                    return { value: category._id, label: category.name };
                });
                setCategoryOptions(formattedOptions);
            } else {
                console.log(response.data.message);
            }
        }).catch((error) => console.log(error));
    };

    const getBanners = () => {
        ApiService.getBannerList().then((response) => {
            if (response.data.status === true) {
                setBanners(response.data.data);
            } else {
                console.error(response.data);
            }
        }).catch((error) => console.log(error));
    };

    const handleEdit = (id) => {
        navigate(`/admin/banner/updatebanner/${id}`);
    };

    const handleProductChange = (selectedOptions) => {
        setProductId(selectedOptions.map((option) => option.value));
    };

    const handleCategoryChange = (selectedOptions) => {
        setCategoryId(selectedOptions.map((option) => option.value));
    };

    const handleIsProductToggle = () => {
        setIsProduct(!isProduct);
        if (isProduct) {
            setCategoryId([]);
        } else {
            setProductId([]);
        }
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const createBanner = (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (isProduct && productId.length > 0) {
            productId.forEach((id, index) => {
                formData.append(`product_id[${index}]`, id);
            });
        } else if (!isProduct && categoryId.length > 0) {
            categoryId.forEach((id, index) => {
                formData.append(`category_id[${index}]`, id);
            });
        }
        formData.append('title', title);
        formData.append('image', image);
        ApiService.createBanner(formData).then((response) => {
            console.log(response.data);
            getBanners();
        }).catch((error) => console.log(error));
    };

    const deleteBanner = (bannerId) => {
        ApiService.deleteBanner({ banner_id: bannerId }).then((response) => {
            console.log(response.data);
            getBanners();
        }).catch((error) => console.log(error));
    };

    return (
        <>
            <form encType='multipart/form-data' className="add-banner-form">
                <div className='select_product_category'>
                    <h2>Add New Banner</h2>
                    <div className='select_product_category_checkbox'>
                        {isProduct ? <span>Select Category</span> : <span>Select Product</span>}
                        <input type='checkbox' checked={!isProduct} onChange={handleIsProductToggle} />
                    </div>
                </div>
                <div className="form-field">
                    {isProduct ?
                        <>
                            <label htmlFor="productName">Select Products</label>
                            <Select
                                options={productOptions}
                                placeholder="Select Products"
                                isSearchable={true}
                                isMulti={true}
                                onChange={handleProductChange}
                                value={productOptions.filter(option => productId.includes(option.value))}
                            />
                        </>
                        :
                        <>
                            <label htmlFor="categoryName">Select Categories</label>
                            <Select
                                options={categoryOptions}
                                placeholder="Select Categories"
                                isSearchable={true}
                                isMulti={true}
                                onChange={handleCategoryChange}
                                value={categoryOptions.filter(option => categoryId.includes(option.value))}
                            />
                        </>
                    }
                </div>
                <div className="form-field">
                    <label htmlFor="bannertitle">Title</label>
                    <input type="text" id="bannertitle" placeholder='Enter Title' value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div className="form-field">
                    <label htmlFor="bannerImage">Banner Image * (Ratio 3:1)</label>
                    <input type="file" id="bannerImage" onChange={handleImageChange} />
                    {image && (
                        <div className="logo-preview">
                            <img width={'400px'} src={URL.createObjectURL(image)} alt="Logo Preview" />
                        </div>
                    )}
                </div>
                <button className="submit-button" onClick={createBanner}>Submit</button>
            </form>
            <div className="banner-list">
                <h2>Banner List</h2>
                <table className="banner-table">
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Title</th>
                            <th>Image</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {banners.map((banner, index) => (
                            <tr key={banner._id}>
                                <td>{index + 1}</td>
                                <td>{banner.title}</td>
                                <td>
                                    <img src={banner.image} alt={banner.title} />
                                </td>
                                <td>
                                    <button onClick={() => handleEdit(banner._id)}>
                                        <i className='bx bx-edit-alt'></i>
                                    </button>
                                    <button onClick={() => deleteBanner(banner._id)}>
                                        <i className='bx bx-trash'></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Bannerpage;
