import React, { useEffect, useState } from 'react'
import ApiService from '../../apicontroller/ApiController';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

export default function UpdateBottomBanner() {
    const [image, setimage] = useState();
    const [file, setFile] = useState();
    const { bannerId } = useParams();
    const getBottomBanner = () => {
        ApiService.getBottomBanner().then((response) => {
            if (response.data.status === true) {
                const bannerList = response.data.data;
                const BannerEdit = bannerList.find((banner) => banner._id === bannerId);
                setFile(BannerEdit.image);
            } else {
                console.log(response.data);
            }
        }).catch((error) => console.log(error))
    }

    useEffect(() => {
        getBottomBanner()
    }, [])
    const handleImageChange = (e) => {
        setimage(e.target.files[0])

    }
    const updateBanner = (e)=>{
        e.preventDefault();
       const formData = new FormData();
       formData.append('image',image);
       formData.append('banner_id',bannerId);
       ApiService.updateBottomBanner(formData).then((response) => {
        if (response.data.status === true) {
            toast.success(response.data.message);

        } else {
            toast.error(response.data.message);
        }
       }).catch((error)=>console.log(error))
    }
    return (
        <>
            <section>
                <form onSubmit={updateBanner}>
                    <div className='form-group'>
                        <label>Bottom Banner</label>
                        <input type='file' onChange={handleImageChange} />
                        {image ?
                            <>
                                {image && (
                                    <div className='logo-preview'>
                                        <img src={URL.createObjectURL(image)} alt='images' width={'200px'} />
                                    </div>
                                )}
                            </> :
                            <>
                            {file && (
                                    <div className='logo-preview'>
                                        <img src={file} alt='images' width={'200px'} />
                                    </div>
                                )}
                            </>}

                    </div>
                        <div>
                            <button type='submit'>Update</button>
                        </div>

                </form>
            </section>
        </>
    )
}
