import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import ApiService from '../../apicontroller/ApiController';
import { useParams } from 'react-router-dom';

export default function UpdateSubCategory() {
    const [image, setImage] = useState(null);
    const [file,setFile] = useState(null);
    const [options, setOptions] = useState([]);
    const [name, setName] = useState('');
    const [categoryId, setCategoryId] = useState(null);
    const {subCategoryId}  = useParams(); // Extract subcategoryId from params
    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const getSubCategory = () => {
        ApiService.getSubCategory().then((response) => {
            const subcategorylist = response.data.data;
            const subcategoryEdit = subcategorylist.find((subcategory)=> subcategory._id === subCategoryId)
            setName(subcategoryEdit.name);
            setFile(subcategoryEdit.image);
            setCategoryId(subcategoryEdit.parent_id);
        }).catch((error) => console.log(error));
    };

    const getCategory = () => {
        ApiService.getCategory().then((response) => {
            if (response.data.status === true) {
                const formattedOptions = response.data.data.map((category) => ({
                    value: category._id,
                    label: category.name,
                }));
                setOptions(formattedOptions);
            } else {
                console.log(response.data.message);
            }
        }).catch((error) => console.log(error));
    };

    useEffect(() => {
        getCategory();
        getSubCategory();
    }, []);

    const handleCategoryChange = (selectedOption) => {
        setCategoryId(selectedOption.value);
    };

    const updateCategory = (e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('image', image);
        formData.append('parent_id', categoryId);
        formData.append('category_id', subCategoryId);
        ApiService.updateCategory(formData).then((response)=>{
                if(response.data.status === true){
                    console.log(response.data)
                }else{
                    console.log(response.data.message)
                }
         }).catch((error)=>console.log(error))
    }

    return (
        <div className="add-new-sub-category">
            <h2>Update Sub Category</h2>
            <form onSubmit={updateCategory}>
                <div>
                    <label htmlFor="main-category">Main Category:</label>
                    <Select
                        options={options}
                        placeholder="Select Category"
                        isSearchable={true}
                        onChange={handleCategoryChange}
                        value={options.find(option => option.value === categoryId)}
                    />
                </div>
                <div>
                    <label htmlFor="sub-category">Sub Category:</label>
                    <input
                        type="text"
                        id="sub-category"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="image">Image (Ratio 1:1):</label>
                    <input
                        type="file"
                        id="image"
                        accept="image/*"
                        onChange={handleImageChange}
                    />
                    {image ? <>
                        {image && (
                        <div className="logo-preview">
                            <img width={'200px'} src={URL.createObjectURL(image)} alt="Logo Preview" />
                        </div>
                    )}
                    </> : 
                    <>
                    {file && (
                        <div className="logo-preview">
                            <img width={'200px'} src={file} alt="Logo Preview" />
                        </div>
                    )}
                    </>}

                </div>
                <button type="submit">Save</button>
            </form>
        </div>
    );
}
