import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import ApiService from '../../apicontroller/ApiController';

export default function UpdateVideoBanner() {
    const [video, setVideo] = useState(null);
    const [existvideo,setExistVideo] =  useState(null);
    const [videoUrl, setVideoUrl] = useState('');
    const [title,setTitle] =  useState('');
    const navigate = useNavigate();
    const {bannerId} = useParams();
    const handleVideoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (videoUrl) {
                URL.revokeObjectURL(videoUrl); // Clean up previous URL
            }
            setVideo(file);
            setVideoUrl(URL.createObjectURL(file));
        }
    };

    const getVideoBanner = ()=>{
        ApiService.getVideoBanner().then((response)=>{
                if(response.data.status === true){                
                  
                    const Bannerlist  = response.data.data;
                    const BannerEdit =  Bannerlist.find((banner)=> banner._id === bannerId);
                    setTitle(BannerEdit.title);
                    setExistVideo(BannerEdit.video);
                }else{
                   toast.error(response.data.message);
                }
        }).catch((error)=> console.error(error))
   }
     useEffect(()=>{
       getVideoBanner();
     },[]);

     const editvideoBanner = (e)=>{
        e.preventDefault();
        if(!video){
            toast.error("Please select a video");
            return;
        }
        const formData = new FormData();
        formData.append('title', title);
        formData.append('video',video);
        formData.append('banner_id',bannerId)
        ApiService.updateVideoBanner(formData).then((response)=>{
                if(response.data.status === true){                
                   toast.success(response.data.message);
                   navigate('/admin/banner/videobanner');
                }else{
                   toast.error(response.data.message);
                }
        }).catch((error)=> console.error(error))
     }

  return (
    <>
        <section>
            <div>
            <form onSubmit={editvideoBanner}>
                <div className='form-group'>
                    <label for="video-title">Video Title:</label>
                    <input type="text" id="video-title" name="video-title" value={title} onChange={(e)=>e.target.value} />
                </div>
                <div className='form-group'>
                    <input type='file' accept="video/*" onChange={handleVideoChange} />
                    {video ?  <>
                        {video && (
                        <video key={videoUrl} width={'300px'} controls loop>
                            <source src={videoUrl} type={video.type} />
                            Your browser does not support the video tag.
                        </video>
                    )}
                    </>:<>
                    {existvideo && (
                        <video key={existvideo} width={'300px'} controls loop>
                            <source src={existvideo} />
                            Your browser does not support the video tag.
                        </video>
                    )} 
                    </>}
                    
                </div>
                <div><button type="submit">Update</button></div>
            </form>
            </div>

        </section>
    </>
  )
}
