import React, { useEffect, useState } from 'react';
import './User.css';
import ApiService from '../../apicontroller/ApiController';

const DefaultPage = () => {
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({});
  const [page,setpage] = useState(1);
  const getUserlist = (pageNo) => {
    ApiService.userlist({
      page:pageNo
    })
      .then((response) => {
        if (response.data.status === true) {
          setCustomers(response.data.data.data);
          setPagination(response.data.data.paginator)
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getUserlist(page);
  }, [page]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handlePageChange=(page)=>{
    setpage(page)
  }
  return (
    <div className="user_list-container">
      <h1 className="user_list-header">Customers</h1>
      <div className="user_list-search-bar">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <table className="user_list-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {customers
            .filter((customer) =>
              customer.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((customer, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{customer.name}</td>
                <td>{customer.email}</td>
                <td>{customer.phone}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="pagination_latestTrend">
      {pagination.totalPages > 1 && (
                        <>
                         
                              <button disabled={!pagination.hasPrevPage} onClick={() => handlePageChange(page - 1)}><i className='bx bx-left-arrow-alt' ></i></button>
                       
                            <span>Page {page} of {pagination.totalPages}</span>
                           
                              <button  disabled={!pagination.hasNextPage}  onClick={() => handlePageChange(page + 1)}><i className='bx bx-right-arrow-alt'></i></button>
                        
                        </>
                    )}
                    </div>
    </div>
  );
};

export default DefaultPage;
