import React, { useState,useEffect } from 'react';
import './Coupon.css';
import Select from 'react-select'
import ApiService from '../../apicontroller/ApiController';
import { toast } from 'sonner';
const AddCouponPage = () => {
  const [title, setTitle] = useState('');
  const [code, setCode] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [User, setUser] = useState('');
  const [discountType, setDiscountType] = useState('amount');
  const [minPurchase, setMinPurchase] = useState('');
  const [discount, setDiscount] = useState('');
  const [startDate, setStartDate] = useState('');
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [options, setOptions] = useState([{value: [], label:' All users'}]);
  // Handles the form submission

  // Handles file input changes
  const handleFileChange = (event) => {
    if (event.target.files) {
      setBackgroundImage(event.target.files[0]);
    }
  };

  const GetUsers = () => {
    ApiService.userlist().then((response) => {
      if (response.data.status === true) {
        console.log(response.data.data)
        const formattedOptions = [
          { value: [], label: 'All users' },
          ...response.data.data.data.map((user) => ({
            value: user._id,
            label: user.name
          }))
        ];
        setOptions(formattedOptions)
      } else {

      }
    }).catch((error) => {
      console.log(error);
    })
  }

  useEffect(()=>{
    GetUsers()
  },[]);

  const CreateCoupon = (e)=>{
    e.preventDefault();
   const formData = new FormData();
    formData.append('title', title);
    formData.append('code',code);
    formData.append('start_date',startDate);
    formData.append('expire_date',expireDate);
    formData.append('discount_type',discountType);
    formData.append('discount',discount);
    formData.append('min_purchase', minPurchase);
    if (backgroundImage) {
      formData.append('image', backgroundImage);
    }
    if (User.length === 1 && User[0].length === 0) {
      formData.append('users', []);
    } else {
      User.forEach((id,index)=>{
        formData.append(`users[${index}]`, id);
      })
      
    }
    ApiService.createCoupon(formData).then((response)=>{
      if(response.data.status === true){
        toast.success(response.data.message)
        setTitle('');
        setCode('');
        setExpireDate('');
        setUser('');
        setDiscountType('amount');
        setMinPurchase('');
        setDiscount('');
        setStartDate('');
        setBackgroundImage(null);
      }else{
        toast.error(response.data.message)
      }
    }).catch((error)=>{
      console.log(error);
      toast.error(error.response.data.message)
    })
  }

  return (
    <>
      <h2 className="add-coupon-title">Add New Coupon</h2>
      <form className="add-coupon-form" onSubmit={CreateCoupon}>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="code">Code</label>
            <input
              type="text"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="form-control"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="startDate">Start Date</label>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="expireDate">Expire Date</label>
            <input
              type="date"
              id="expireDate"
              value={expireDate}
              onChange={(e) => setExpireDate(e.target.value)}
              className="form-control"
            />
          </div>

        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="limitForSameUser">Select User</label>
            <Select
              options={options}
              placeholder="Select Users"
              isSearchable={true}
              isMulti={true}
              onChange={(selectedOptions) => setUser(selectedOptions ? selectedOptions.map(option => option.value) : [])}
            />
          </div>
          <div className="form-group">
            <label htmlFor="discountType">Discount Type</label>
            <select
              id="discountType"
              value={discountType}
              onChange={(e) => setDiscountType(e.target.value)}
              className="form-control"
            >
              <option value="amount">Amount</option>
              <option value="percent">Percent</option>
              {/* Add more discount types here */}
            </select>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="minPurchase">Min Purchase</label>
            <input
              type="number"
              id="minPurchase"
              value={minPurchase}
              onChange={(e) => setMinPurchase(e.target.value)}
              className="form-control custom_number_input"
              min="0"
            />
          </div>
          <div className="form-group">
            <label htmlFor="discount">Discount</label>
            <input
              type="number"
              id="discount"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              className="form-control custom_number_input"
              min="0"
            />
          </div>
        </div>
        <div className="form-row">

          <div className="form-group">
            <label htmlFor="backgroundImage">Upload Background Image</label>
            <input
              type="file"
              id="backgroundImage"
              onChange={handleFileChange}
              className="form-control"
            />
             {backgroundImage && (
              <div className="image-preview">
                <img src={URL.createObjectURL(backgroundImage)} alt="Images Preview" />
              </div>
            )}
          </div>
        </div>
        <button type="submit" className="button">
          Submit
        </button>
      </form>
    </>
  );
};

export default AddCouponPage;
