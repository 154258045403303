import React, { useEffect, useState } from 'react'
import './orderdetails.css';
import ApiService from '../../apicontroller/ApiController';
import { toast } from 'sonner';
import { useParams } from 'react-router-dom';
import { formatDateTime } from '../../helpers/FormatDate';
import Modal from 'react-modal';
import Swal from 'sweetalert2';

const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)', // Darken the background
      zIndex: 1000, // Ensure it appears above other content
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff', // Background color
      padding: '20px', // Padding inside the modal
      borderRadius: '10px', // Rounded corners
      width: '80%', // Width of the modal
      maxWidth: '500px', // Maximum width
    },
  };
export default function OrderDetails() {
    const [orderdetails, setOrderDetails] = useState({});
    const { orderId } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const handleViewMoreClick = (image) => {
        setSelectedImage(image);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const fetchOrderDetails = () => {
        ApiService.getOrderdetails({
            order_id: orderId
        }).then((response) => {
            if (response.data.status === true) {
                console.log(response.data)
                setOrderDetails(response.data.data)
            } else {

            }
        }).catch((error) => {
            console.error(error);
            toast.error(error.response.data.message)
        })
    }
    useEffect(() => {
        fetchOrderDetails();
    }, []);

    const handleChangeStatus = (event) => {
        const newStatus = event.target.value;
        console.log(newStatus);
        Swal.fire({
            title: "Are you sure?",
            text: `Change status to ${newStatus}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
          }).then((result) => {
            if (result.isConfirmed) {
                ApiService.ChangeOrderStatus({
                    order_id: orderId,
                    status: newStatus,
                }).then((response) => {
                    if (response.data.status === true) {
                        toast.success("Order status updated successfully");
                        fetchOrderDetails(); // Refresh the order details after status update
                    } else {
                        toast.error("Failed to update order status");
                    }
                }).catch((error) => {
                    console.error(error);
                    toast.error(error.response?.data?.message || "An error occurred");
                });
            }
          });
        
    };
    return (
        <>
            <section className='order_details_info'>
                <article className='order_details_info_1' >
                    <section className='order_details_info_2'>
                        <div className='order_details_info_3'>
                            <div>
                                <span>Order #{orderdetails.order_id}</span>
                            </div>
                            <div>
                                <span><i class='bx bx-calendar' ></i></span>
                                <span>{formatDateTime(orderdetails.created_at)}</span>
                            </div>
                        </div>
                        <div className='order_details_info_3'>
                            <div className='order_details_info_4'>
                                <span>Status : <span className={`order_details_status ${orderdetails.order_status === 'pending' ? 'pending' : orderdetails.order_status === 'confirmed' ? 'confirmed' : orderdetails.order_status === 'cancelled' ? 'cancelled' : 'delivered'}`}>{orderdetails.order_status}</span></span>
                            </div>
                            <div>
                                <span>Order Type : <span className='order_details_status pending'>{orderdetails.order_type}</span></span>
                            </div>
                        </div>
                    </section>

                    <section className='order_details_info_6'>
                        <table>
                            <thead>
                                <th>#</th>
                                <th>Item Details</th>
                                <th>Price</th>
                            </thead>
                            <tbody>
                                {orderdetails?.items?.map((product, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                            <section className='order_details_info_5'>

                                                <div className='order_details_info_13'>
                                                    <img src={product.product_details.image} alt={product.name} />
                                                </div>
                                                <div>
                                                    <h4>{product.product_details.name}</h4>
                                                    {product.variant ?<p className='item_details_p'>Size : {product.variant ? product.variant.size : 'No'}</p> : '' }
                                                    
                                                   {product.color ?<p className='item_details_p'>Color : {product.color ? product.color.color_name : "No"}</p> : ''} 
                                                    <p> {product.quantity} x {product.product_details.price}</p>
                                                    {orderdetails.order_type === 'personalize' ?
                                                        <div>
                                                    {product.personalize_image !== null ? <>
                                                        <button
                                                                className='order_details_info_14'
                                                                onClick={() => handleViewMoreClick(product.personalize_image)}
                                                            >view more</button>
                                                    </>:''}


                                                        </div>
                                                        : ''}
                                                </div>
                                            </section>
                                        </td>
                                        <td>₹{product.quantity * product.product_details.price}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <hr />
                        <div className='order_details_info_12'>

                            <h4>Total</h4>
                            <p>₹{orderdetails.order_amount}</p>
                        </div>
                    </section>
                </article>
                <article className='order_details_info_11'>
                    <section className='order_details_info_7'>
                        <div className='order_details_info_9'>
                            <h4>Order Setup</h4>
                            <hr />
                        </div>
                        <div className='order_details_info_8'>
                            <select value={orderdetails.order_status} onChange={handleChangeStatus}>
                                <option value='pending' selected>Pending</option>
                                <option value='confirmed'>Confirmed</option>
                                <option value='delivered'>Delivered</option>
                                <option value='cancelled'>Cancelled</option>
                            </select>
                        </div>
                    </section>
                    <section className='order_details_info_10'>
                        <div>
                            <i class='bx bxs-user' ></i>Customer Information
                        </div>
                        <div>
                            <p>{orderdetails.user?.name}</p>
                            <p>29 Orders</p>
                            <p><i class='bx bxs-phone-outgoing' ></i> +91{orderdetails.user?.phone}</p>
                            <p><i class='bx bxs-envelope' ></i> {orderdetails.user?.email}</p>
                        </div>
                    </section>
                </article>
            </section>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Personalized Product Image"
                style={customStyles}
            >
            <div className='order_details_pop_image'>
                <img src={selectedImage} loading='eager' alt="Personalized Product" style={{ width: '200px' }} />
            </div>
            
                {/* <button onClick={closeModal}>Close</button> */}
            </Modal>
        </>
    )
}
