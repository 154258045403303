import moment from 'moment';

const formatDate =  (date)=>{
    return moment(date).format('DD-MM-YYYY');
  }
const formatDateTime =  (date)=>{
    return moment(date).format('LLLL');
  }


  export {formatDate,formatDateTime};