import { CssBaseline } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthContext';

// Ensure that `document.getElementById` returns an HTML element
const rootElement = document.getElementById('root');

// Create the root for rendering
const root = ReactDOM.createRoot(rootElement);

// Render the application
root.render(
  <React.StrictMode>
  <AuthProvider>
    <Provider store={store}>
      <CssBaseline />
      <App />
    </Provider>
  </AuthProvider>
  </React.StrictMode>
);

