import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  appState: ""
};

// Create slice
export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setAppState: (state, action) => {
      state.appState = action.payload;
    }
  }
});

// Export actions and reducer
export const { setAppState } = appStateSlice.actions;
export default appStateSlice.reducer;
